const isMobile = {
  android: function () { return navigator.userAgent.match(/Android/i); },
  blackberry: function () { return navigator.userAgent.match(/BlackBerry/i); },
  ios: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
  opera: function () { return navigator.userAgent.match(/Opera Mini/i); },
  windows: function () { return navigator.userAgent.match(/IEMobile/i); },
  any: function () { return navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i); },
};

export default isMobile
