import { QueryClient, QueryObserver } from 'react-query';
import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

let queryClient
// export interface Config<TToken, TLoginParams> {
//   tokenExpired: (token: TToken) => boolean;
//   refreshExpired: (token: TToken) => boolean;
//   sendLogin: (loginParams: TLoginParams) => Promise<TToken>;
//   sendRefresh: (token: TToken) => Promise<TToken>;
//   retry: (failCount: number, error: any) => boolean;
//   refreshExpiredError: any;
//   queryKey?: string;
//   shouldRefreshOnBackground?: (token: TToken) => boolean;
// }

// interface TToken {
//     accessToken: string;
//     refreshToken: string;
//     accessTokenExpiry: number;
//     refreshTokenExpiry: number;
// }

function createTokenQuery({
  queryKey = 'token',
  tokenExpired,
  refreshExpired,
  sendLogin,
	sendRefresh,
	loggedIn:loggedInCheck,
	onLogout,
  retry,
  refreshExpiredError,
  shouldRefreshOnBackground,
}) {
	let tokenRefreshIntervalHandler;
	let tokenRefreshInterval;
	// let tokenLockPromise

	/* const getTokenId = () => {
		let id = localStorage.getItem(queryKey + "-id")
		if (!id) {
			// no id, generate one.
			const newId = 'web'
			localStorage.setItem(queryKey + "-id", newId)
			id = newId
		}
		return id
	} */

  const getTokenFromStorage = () => {
    const storedValue = localStorage.getItem(queryKey);

    if (!storedValue) {
      return undefined;
    }

    let token;

    try {
      token = JSON.parse(storedValue);
      // eslint-disable-next-line no-empty
    } catch {}

    return token;
  };

  const setTokenValue = (token) => {
    if (token === undefined) {
			if (onLogout) onLogout();
			localStorage.removeItem(queryKey);
    } else {
      localStorage.setItem(queryKey, JSON.stringify(token));
    }

    queryClient.setQueryData(queryKey, token);
  };

  const refresh = async (throwOnError = false) => {
		const token = queryClient.getQueryData(queryKey);
		console.log('sending refresh', token)
		// try {
			queryClient.removeQueries(`temp-refresh-${queryKey}`);
			const newToken = await queryClient.fetchQuery({
				queryKey: [`temp-refresh-${queryKey}`],
				queryFn: () => sendRefresh(token),
				config: {
					retry,
					throwOnError,
					// staleTime: 0,
					// cacheTime: 0
				}
			});
			console.log('newToken:refresh', newToken)

			// If token is undefined then refresh has failed
			if (newToken !== undefined) {
				setTokenValue(newToken);
			}

			queryClient.removeQueries(`temp-refresh-${queryKey}`);

			return newToken;
		// } catch(e) {
		// 	console.log('token-query send refresh error:', e)
		// 	clearToken()
		// 	return undefined
		// }
  };

  const startBackgroundRefreshing = () => {
    clearInterval(tokenRefreshIntervalHandler);

    tokenRefreshIntervalHandler = setInterval(() => {
      refresh();
    }, tokenRefreshInterval);
  };

  const stopBackgroundRefreshing = () => {
    clearInterval(tokenRefreshIntervalHandler);
  };

  const login = async (loginParams) => {
		const token = await queryClient.fetchQuery({
      queryKey: [`temp-login-${queryKey}`],
      queryFn: () => sendLogin(loginParams),
      config: {
        retry,
        throwOnError: true,
				// staleTime: 0,
				// cacheTime: 0
      }
    });

    if (tokenRefreshInterval) {
      startBackgroundRefreshing();
    }

    queryClient.removeQueries(`temp-login-${queryKey}`);

    return token;
  };

  const logout = async () => {
    setTokenValue(undefined);
    stopBackgroundRefreshing();
  };

  const useLogin = () => {
    const [data, setData] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);

    const requestLogin = async (
      loginParams,
      throwOnError = false
    ) => {
      setIsFetching(true);
      setData(null);
      setError(null);

      try {
        const token = await login(loginParams);

        setIsFetching(false);
        setData(token);
        setTokenValue(token);

        return token;
      } catch (loginError) {
        setIsFetching(false);
        setError(loginError);

        if (throwOnError) {
          throw loginError;
        }
      }

      return undefined;
    };

    return { data, isFetching, error, requestLogin };
  };

  const useToken = () => {
		// console.log('useToken')
    const existingToken = queryClient.getQueryData(queryKey);
    const [token, setToken] = useState(existingToken);

    useEffect(() => {
			const observer = new QueryObserver(queryClient, { queryKey })
      const unsubscribe = observer.subscribe(async (newQueryCache) => {
				// console.log("updating token", tokenLockPromise)
				// await tokenLockPromise
        const newToken = queryClient.getQueryData([queryKey]);
				console.log('useEffect in useToken: newQueryCache', newQueryCache, newToken)
        if (!isEqual(token, newToken)) {
          setToken(newToken);
        }
      });

      return () => {
        unsubscribe();
      };
		});

		// console.log("useToken token", token)
    return token;
  };

  const getToken = async (force = false) => {
    const token = queryClient.getQueryData(queryKey);

    if (token === undefined) return undefined;

    if (tokenExpired(token) || force) {
			if (refreshExpired(token)) {
				throw refreshExpiredError;
			}
      const newToken = await refresh(true);

			console.log('newToken in getToken', newToken)
			// if (newToken === undefined) {
			// 	clearToken()
			// }

      return newToken;
    }

    if (shouldRefreshOnBackground && shouldRefreshOnBackground(token)) {
      refresh();
    }

    return token;
  };

  const init = async (refreshInterval) => {
    if (refreshInterval) {
      tokenRefreshInterval = refreshInterval;
		}

		// const id = getTokenId()
		const token = getTokenFromStorage();
		console.log('queryClient init', queryClient)
		queryClient.setQueryData(queryKey, token);

		// console.log('get token in init', token)

    if (!token || tokenExpired(token)) {
			// console.log('refreshExpired(token)', refreshExpired(token), token?.refreshTokenExpiry)
			if (refreshExpired(token))
				setTokenValue(undefined);
			else if (token?.refreshTokenExpiry) {
				try {
					await refresh(false)
				} catch(e) {

				}
			}

      return;
    }

    setTokenValue(token);

    if (refreshInterval) {
      startBackgroundRefreshing();
    }
	};

	const callLogin = async (loginParams) => {
		try {
			const token = await login(loginParams);
			setTokenValue(token);
		} catch (loginError) {
			throw loginError
		}
	}

	const loggedIn = () => {
		const token = queryClient.getQueryData(queryKey);
    if (token === undefined) return undefined;
		return loggedInCheck(token)
	}

	const clearToken = () => {
		localStorage.removeItem(queryKey);
	}

  return { init, useLogin, callLogin, useToken, logout, refresh, getToken, loggedIn, clearToken };
}

export function setQueryClient(client) {
	queryClient = client
}

export default createTokenQuery;
