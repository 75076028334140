import React from 'react'
import { Helmet } from "react-helmet";

const head = {
	"title":"ChariTrek",
	"description":"ChariTrek® exists to connect people through experiences. We use technology to provide a dynamic digital environment to share information, adventures and interests. We make it easy to create and enjoy personal journeys that connect humanity and enhance lives.",
	"url":"https://charitrek.com",
	"socialImage":"https://charitrek.com/images/touch/social.png",
	"siteIcons":"path-to-site-icons",
	"keywords":"charitrek,charistory,charitour,shareatrek,treks,tours",
	"twitter":{
		"site":"@charitreks",
		"creator":"@charitreks",
		"img":"path"
		}
	}

export default function Meta(props) {
	return (
		<Helmet>
			<title>{head?.title ?? props?.title}</title>
			<meta name="description" content={head?.description ?? props?.description} />
			<meta property="og:title" content={head?.title ?? props?.title} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={head?.url ?? props?.url} />
			<meta property="og:image" content={head?.socialImage ?? props?.socialImage} />
			{ !props?.image?.width && <meta property="og:image:width" content="props.image.width" /> }
			{ !props?.image?.height && <meta property="og:image:height" content="props.image.height" /> }
			<meta property="og:description" content={head?.description ?? props?.description} />
			<meta name="keywords" content={head?.keywords ?? props?.keywords} />
			<link rel="canonical" href={head?.url ?? props?.url} />
		</Helmet>
	)
}
