import React, { memo, useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react'
import { Helmet } from "react-helmet"
import { Link, useLocation, useHistory } from 'react-router-dom'
import { axios } from "utils/axios"
import "./TrekDetail.scss"
import { useQuery, useQueryClient } from "react-query"
import NoMatch from 'pages/NoMatch'
import LocalizedText from 'data/LocalizedText'
import { selectors as AS } from 'reducers/app'
import { useSelector } from 'react-redux'
// import tokenQuery from 'utils/token'
import ReviewIcon from 'components/ReviewIcon'
import StarReviewIcons from 'components/StarReviewIcons'
import ReviewGroup from './components/ReviewGroup'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Pagination, A11y, Autoplay } from 'swiper'
import { toNumericalDecimal } from 'utils/string'
import { UserContext } from 'contexts/userContext'
import { PurchaseContext } from 'contexts/purchaseContext'

SwiperCore.use([Pagination, A11y, Autoplay])

const Home = memo(() => {
	// const [trek, setTrek] = useState({
	// 	title: new LocalizedText(),
	// 	summary: new LocalizedText(),
	// 	description: new LocalizedText(),
	// 	languages: [],
	// 	genre: '',
	// 	author: '',
	// 	version: '',
	// 	price: 0,
	// 	reviewCount: 0,
	// 	promotional_photos: [],
	// 	reviews: undefined,
	// 	rating: undefined,
	// 	completionTime: undefined,
	// 	updatedAt: "N/A"
	// })
	const {pathname} = useLocation()
	// const tokens = tokenQuery.useToken()
	const history = useHistory()
	const dataRef = useRef()
	const [purchased, setPurchased] = useState(false)
	const [favorited, setFavorited] = useState(false)
	const { showPurchase, showInstructions } = useContext(PurchaseContext)

	const {user:me} = useContext(UserContext)
	// const qc = useQueryClient()
	// const me = qc.getQueryData('me')

	// const axios = useAxios()
	const { status, data, error, isFetching } = useQuery([pathname], async () => {
		console.log('pathname', pathname)
		return new Promise(async (resolve, reject) => {
			// const token = await tokenQuery.getToken();
			// console.log('token', tokens)
			axios.get(process.env.REACT_APP_API_URL + pathname + '/detail', {
				_auth: true,
				// headers: {
				// 	cookie:'',
				// },
				// ...tokens?.accessToken ? { headers: { Authorization: `Bearer ${tokens?.accessToken}` } } : {},
				// withCredentials: true,
				// validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
			}).then(res => res.data).then(data => {
				console.log('data', data)
				dataRef.current = data
				if (data.length <= 0)
					reject('No data')
				resolve(data)
			}).catch(e => {
				console.log('trekdetail error:', e)
				reject(e)
			});
		})
		// const { data } =
		// if (data?.length > 0) {

		// }
	  // return data;
	}, {
		// enabled: !tokenQuery.tokenExpired(tokens),
		cacheTime: 5 * 60 * 1000, // 2 min
		// retry: 1
		// retry: 0,
		// staleTime: 2 * 60 * 1000, // 2 min
		// initialData:() => window.__ct?.INITIAL_PATH === pathname ? window.__ct?.SERVER_DATA : undefined
	});

	/* console.log({
		status, data, error, isFetching, pathname
	}) */


	// console.log('window.INITIAL_PATH == pathname', window.INITIAL_PATH == pathname)
	/* if (isFetching) {
		return <div>
			<p>
				Trek Loading ... please wait.
			</p>
			<Link to='/'> Home </Link>
		</div>
	} */


	const { title, summary, description, languages, genre, rating, completionTime, updatedAt, reviewCount, author, version, price, promotional_photos, reviews, uuid } = data?.[0] ?? data?.data?.[0] ?? {}
	const trek = useMemo(() => ({
			title: new LocalizedText(title),
			summary: new LocalizedText(summary),
			description: new LocalizedText(description),
			languages,
			genre,
			author,
			version,
			price,
			rating,
			promotional_photos,
			reviews,
			completionTime,
			reviewCount,
			updatedAt,
			uuid
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [data])

	useEffect(() => {
		if (me) {
			const trekPurchased = me.treks_purchased.filter(tp => tp.trek_uuid === trek.uuid)[0]
			const trekFavorited = me.treks_favorited.filter(tf => tf.trekUuid === trek.uuid)[0]
			setPurchased(trekPurchased)
			setFavorited(trekFavorited)
		}
	}, [me, trek])

	const purchaseEvent = useCallback(
		async () => {
			console.log("purchase event")
			// const url = `${window.location.origin}/t/${trek.uuid}`
			console.log('trek', trek.uuid)
			showPurchase(trek?.uuid)
		},
		[trek, showPurchase],
	)
	const instructionEvent = useCallback(
		async () => {
			console.log("purchase event")
			// const url = `${window.location.origin}/t/${trek.uuid}`
			console.log('trek', trek)
			showInstructions(trek.uuid)
		},
		[trek, showInstructions],
	)
	const d = new Date(trek.updatedAt)
	const updatedAt2 = d.toLocaleDateString()

	const promos = trek?.promotional_photos?.map((i, index) =>
							<SwiperSlide key={`promo-${index}`}>
								<div className='slide image' style={{backgroundImage:`url("${i.uri_image}")`}}/>
							</SwiperSlide>
						) ?? []

	const settings = {
		// dots: true,
		// infinite: true,
		// speed: 500,
		// slidesToShow: 1,
		autoplay: {
			delay: 5000,
		},
		navigation:true,
		scrollbar:{ draggable: true },
		slidesPerView: 'auto',
		centeredSlides:true,
		grabCursor: true,
		keyboard: { enabled: true },
		loop: promos.length > 1,
		spaceBetween: 10,
		pagination: promos.length > 1 ? {
			el: '.swiper-pagination',
			clickable: true,
		} : {},
		// centerMode: true,
		// centerPadding: '40px',
		// variableWidth: true,
		/* responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					// slidesToScroll: 1,
				}
			}
		] */
		// dots: true,
		// infinite: true,
		// speed: 500,
		// slidesToShow: 3,
		// centerMode: true,
		// useCSS: true,
		// slidesToScroll: 1,
		// variableWidth: true,
	};

	const legalInfo = <div>
						<div className="section">
							<h4>Legal Information</h4>
							<p>None provided.</p>
						</div>
						<div className='details'>
							<h4>Trek Details</h4>
							<p>Last update: {updatedAt2}</p>
							<p className='version'>Version: { trek?.version } </p>
							<p>Languages Supported: { trek?.languages?.map(l => l.iso).join(',') }</p>
							<div className="report">
								<p>Report</p>
							</div>
						</div>
					</div>

	if (!isFetching && (error || !data || data.length <= 0)) {
		return <NoMatch
			showLayout={false}
			title="Oops!"
			subTitle="The Trek you are looking for could not be found or is not available."
			login
			/>
	}

	return (
		<div className="trek-detail">
			<Helmet>
				<title>{trek?.title?.text} - ChariTrek</title>
			</Helmet>
			<div className='heading'>
				<div className='btn-container'>
					<button className='btn-back' onClick={() => history.goBack()}>◀︎ Back to Browsing </button>
				</div>
				<div className='images'>
					<Swiper {...settings} children={promos}>
					</Swiper>
    			<div className="swiper-pagination"/>
				</div>
				<div className='action-top'>
					<p className='title'>{ trek?.title?.text } </p>
					<div className='heading-right'>
						<div className='author'>
							{ trek?.author?.photo
								? <img src={trek?.author?.photo} alt="Author" />
								: <i className='icon ct-r' >&#xf128;</i> }
							<p>{ trek?.author?.author } </p>
						</div>

					</div>
				</div>
				<div className="action-bar">
					<div className="content">
						<div className="col-left">
							<ReviewIcon value={trek.rating?.difficult} type="dif" />
							<ReviewIcon value={trek.rating?.educational} type="edu" />
							<ReviewIcon value={trek.rating?.fun} type="fun" />
						</div>
						<div className='col-right'>
							<i className={`icon ${favorited ? 'ct-s' : 'ct-r'}`}>&#xf106;</i>
							<i className="icon ct-r">&#xf12e;</i>
							{ purchased
								? <div onClick={instructionEvent} className='price'>PLAY</div>
								: <div onClick={purchaseEvent} className='price'>{trek?.price === 0 ? "FREE" : `$${toNumericalDecimal(trek?.price ?? 0)}`}</div>
							}
						</div>
					</div>
				</div>
				<div className="action-lower">
					<p className='review'><StarReviewIcons value={trek?.rating?.overall ?? 0} /> <span>{trek?.rating?.reviews ?? 0} Reviews</span></p>
					<div className='achievements'>
						Achievements
						<i className='icon ct-s'>&#xf101;</i>
					</div>
				</div>
			</div>
			<div className="trek-detail-body">
				<div className="trek-detail-body-container">
					<div className="column column-left">
						<p>{ trek?.genre ? trek?.genre?.text?.toString() : "" } </p>
						<div className="section">
							<h4>Summary</h4>
							<p>{ trek?.summary?.text || "None provided." } </p>
						</div>
						<div className="section">
							<h4>Description</h4>
							<p>{ trek?.description?.text || "None provided." } </p>
						</div>
						<div className="section">
							<h4>Directions</h4>
							<p>{ trek?.directions?.text || "None provided." } </p>
						</div>
						<div className="section desktop">
							{legalInfo}
						</div>
					</div>
					<div className="column column-right">
						<ReviewGroup trek={trek} />
					</div>
					<div className="column column-left mobile">
						{legalInfo}
					</div>
				</div>
			</div>
		</div>
	)
})

export default Home
