// import { createSelector } from 'reselect'

// ------------------------------------
// Constants
// ------------------------------------
export const APP_TOKENS = 'APP_TOKENS'
export const APP_USER = 'APP_USER'
export const APP_SETUP = 'APP_SETUP'
export const APP_VERIFIED = 'APP_VERIFIED'
export const APP_STATE = 'APP_STATE'
export const APP_RESET = 'APP_RESET'

// ------------------------------------
// Actions
// ------------------------------------
export const setTokens = ({accessToken, accessTokenExpiry}) => ({ type: 'APP_TOKENS', accessTokenExpiry, accessToken })
export const setUser = (user) => ({ type: 'APP_USER', user })
export const setSetup = (setup) => ({ type: 'APP_SETUP', setup })
export const setVerified = (verified) => ({ type: 'APP_VERIFIED', verified })
export const setState = (state) => ({ type: 'APP_STATE', ...state })
export const reset = () => ({ type: 'APP_RESET' })

const actions = {
	setTokens,
	setUser,
	setSetup,
	setVerified,
	setState,
	reset,
}
export { actions, actions as AA }

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [APP_TOKENS]: (state, { type, ...actions }) => ({ ...state, tokens: { ...actions }, loggedIn:true }),
  [APP_USER]: (state, { type, ...actions }) => ({ ...state, user:actions }),
  [APP_SETUP]: (state, { setup }) => ({ ...state, setup }),
  [APP_VERIFIED]: (state, { verified }) => ({ ...state, verified }),
  [APP_STATE]: (state, { type, ...actions }) => ({ ...state, ...actions }),
  [APP_RESET]: () => ({ ...initialState }),
}

const selectors = {
  // online: createSelector(({ app: state }) => state?.isConnected, (c) => c),
  tokens: ({ app: { tokens } }) => tokens,
  loggedIn: ({ app: state }) => state.loggedIn,
  user: ({app:state}) => state.user,
  setup: ({ app: state }) => state.setup,
  verified: ({ app: state }) => state.verified,
}
export { selectors, selectors as AS }


// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	tokens: {
		accessToken: undefined,
		accessTokenExpiry: undefined,
		refreshToken: undefined,
		refreshTokenExpiry: undefined,
	},
	loggedIn: false,
	user: undefined,
	setup: false,
	verified: false,
}
const Reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
export default Reducer
