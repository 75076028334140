import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Valid from './helpers/validations'
import axios from 'axios'
import Helmet from 'react-helmet'

async function sendToFakeServer(values) {

  return values;
}

const PwForgot = {
	auth: false,
	onSubmit:async (values, instance, cancelToken) => {
		// await new Promise(resolve => setTimeout(resolve, 10000));
		// const params = new URLSearchParams();
		// params.append('param1', 'value1');
		let {data, status} = await axios.post(process.env.REACT_APP_API_URL + `/auth/local/forgot`, values, {
			cancelToken,
			validateStatus: function (status) { return status < 500; /* Resolve only if the status code is less than 500 */ }
		})
		if (status === 200)
		{
			return [null, data?.success, '/login']
		} else {
			return [{id:"errorReset", message:data?.error ?? "There was an error attempting to reset your password."}]
		}
	},
	bg:BG.AIRPLANE,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Trouble logging in?</h1>
				</div>
				<p>We will e-mail you a unique code to help reset your password.</p>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Forgot Password - ChariTrek</title>
			</Helmet>
			<div style={{flexGrow:1}}/>
			<h1>Reset your password</h1>
			<Form>
				<InputField field="email" placeholder="E-mail" type='email' required />
				<div>
					<button type="submit" disabled={isSubmitting || !canSubmit}>
						{isSubmitting ? "Emailing..." : "EMAIL ME"}
					</button>
				</div>
			</Form>
			<div style={{flexGrow:1}}/>
			<Link to="/login">
				<h4 style={{marginTop:'40px'}}>Back to login</h4>
			</Link>
		</div>
	)
}
export default PwForgot
