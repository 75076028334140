// import { Platform } from 'react-native'
/* app.js */

export const Colors = {
  'warm-grey-heavy': '#695B6B',
  'warm-grey-light': '#7A6E7C',
  'chosen-purple': '#6D4191',
  'action-pink': '#A756AB',
  'charitrek-even-darker-blue': '#3FBAD6',
  'light-grey': '#BBBBBB',
  'lighter-grey': '#eeeeee',
  'charitrek-medium-blue': '#8BD2E8',
  'charitrek-dark-green': '#A4BD48',
  'charitrek-light-green': '#C8D853',
  'charitrek-grey': '#666666',
  'charitrek-light-blue': '#C7E9F3',
  'charitrek-dark-blue': '#60C6DD',
  'default-text': '#ffffff',
  'dark-grey': '#3c3843'
}

/* module.hot.accept(() => {
  EStyleSheet.clearCache()
  EStyleSheet.build() // force style re-calculation
}) */

const Global = {
  $warmGreyHeavy: Colors['warm-grey-heavy'],
  $warmGreyLight: Colors['warm-grey-light'],
  $chosenPurple: Colors['chosen-purple'],
  $actionPink: Colors['action-pink'],
  $charitrekEvenDarkerBlue: Colors['charitrek-even-darker-blue'],
  $lightGrey: Colors['light-grey'],
  $lighterGrey: Colors['lighter-grey'],
  $charitrekMediumBlue: Colors['charitrek-medium-blue'],
  $charitrekDarkGreen: Colors['charitrek-dark-green'],
  $charitrekLightGreen: Colors['charitrek-light-green'],
  $charitrekGrey: Colors['charitrek-grey'],
  $charitrekLightBlue: Colors['charitrek-light-blue'],
  $charitrekDarkBlue: Colors['charitrek-dark-blue'],
  $defaultTextColor: Colors['default-text'],
}
export default Global

export const globalFunc = {
  tc: (color) => ({ color }),
  bc: (backgroundColor) => ({ backgroundColor }),
  tf: (...v) => ({ transform: [...v] }),
  s: function (v) {
    return { scale: v }
  },
  r: function (v) {
    return { rotate: `${v}deg` }
  },
  ty: function (v) {
    return { translateY: v }
  },
  tx: function (v) {
    return { translateX: v }
  },
  shadow: function (color = 'rgba(0, 0, 0, 0.1)', x = 1, y = 1, blur = 1) {
    return {
      textShadowColor: color,
      textShadowOffset: { width: x, height: y },
      textShadowRadius: blur
    }
  },
  hex2rgba: (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
}

export const globalStyle = {
  warmGreyHeavy: { color: '$warmGreyHeavy' },
  warmGreyLight: { color: '$warmGreyLight' },
  chosenPurple: { color: '$chosenPurple' },
  actionPink: { color: '$actionPink' },
  charitrekEvenDarkerBlue: { color: '$charitrekEvenDarkerBlue' },
  lightGrey: { color: '$lightGrey' },
  lighterGrey: { color: '$lighterGrey' },
  charitrekMediumBlue: { color: '$charitrekMediumBlue' },
  charitrekDarkGreen: { color: '$charitrekDarkGreen' },
  charitrekLightGreen: { color: '$charitrekLightGreen' },
  charitrekGrey: { color: '$charitrekGrey' },
  charitrekLightBlue: { color: '$charitrekLightBlue' },
  charitrekDarkBlue: { color: '$charitrekDarkBlue' },
  defaultTextColor: { color: '$defaultTextColor' },
  p: {
    color: '$defaultTextColor',
    // lineHeight: '1.25rem',
    fontFamily: 'ba-Light',
  },
  h2: {
    fontSize: '2rem',
    fontFamily: 'ba-Light'
  },
  h3: {
    fontSize: '1.75rem',
    fontFamily: 'ba-Light'
  },
  h4: {
    fontSize: '1.5rem',
    fontFamily: 'ba-Light'
  },
  h5: {
    fontSize: '1.3rem',
    fontFamily: 'ba-Light'
    // paddingTop: (Platform.OS === 'ios' ? '1.3rem' : 0),
    // paddingBottom: '-1.3rem'
  },
  h6: {
    fontSize: '1.1rem',
    fontFamily: 'ba-Light'
    // paddingTop: (Platform.OS === 'ios' ? '1.3rem' : 0),
    // paddingBottom: '-1.3rem'
  },
  bold: {
    fontWeight: 900,
  },
  input: {
    backgroundColor: '$defaultTextColor',
    color: '$charitrekGrey',
    fontFamily: 'ba-Light',
    fontSize: 18,
    padding: 10,
    marginHorizontal: 5,
    marginVertical: 5
  },
  button: {
    backgroundColor: '$chosenPurple',
    color: '$defaultTextColor',
    fontFamily: 'ba-Light',
    textAlign: 'center',
    padding: 10,
    marginHorizontal: 5,
    marginVertical: 5,
    flexGrow: 1,
  },
  icon: {
    fontSize: 16,
    fontFamily: 'fal',
    color: '$defaultTextColor',
    textAlign: 'center'
  },
  shadow: {
    textShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)',
  },
  boxShadow: {
		boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.1)',
  },
  fs: {
    flex: 1
  },
  fg: {
    flexGrow: 1
  },
  far: { fontFamily: 'far' },
  fal: { fontFamily: 'fal' },
  fab: { fontFamily: 'fab' },
  fas: { fontFamily: 'fas' },
  icons: { fontFamily: 'icons' },
  iconRating: { color: '#8BD2E8' },
  iconRating1: { color: '#60C6DD' },
  iconRating2: { color: '#3FBAD6' },
  bal: { fontFamily: 'ba-Light' },
  bar: { fontFamily: 'ba-Regular' },
  bab: { fontFamily: 'ba-Bold' },
  weather: { fontFamily: 'weather' },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  fw: {
    width: '100%'
  },
  fh: {
    height: '100%'
  },
  flexH: {
    flexDirection: 'row'
  },
  flexV: {
    flexDirection: 'column'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  grow: {
    flexGrow: 1,
  },
  noGrow: {
    flexGrow: 0,
  },
  shrink: {
    flexShrink: 1,
  },
  noShrink: {
    flexShrink: 0,
  },
  flexStatic: {
    flexGrow: 0,
    flexShrink: 0,
    flex: 0
  },
  jc: {
    justifyContent: 'center'
  },
  ac: {
    alignItems: 'center'
  },
  tc: {
    textAlign: 'center'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  spaceEvenly: {
    justifyContent: 'space-evenly'
  },
  debug: {
    borderWidth: 1,
    borderColor: '#ff0000'
  },
  ct_r: {
    fontFamily: 'stroke',
    lineHeight: 38,
    fontSize: 38,
    textAlignVertical: 'bottom'
  },
  ct_c: {
    fontFamily: 'contained',
    lineHeight: 28,
    fontSize: 28,
    textAlignVertical: 'bottom'
  },
  ct_s: {
    fontFamily: 'solid',
    lineHeight: 38,
    fontSize: 38,
    textAlignVertical: 'bottom'
  },
  transparent: {
    backgroundColor: 'transparent'
  },
  toUpper: {
    textTransform: 'uppercase'
  },
  toLower: {
    textTransform: 'lowercase'
  },
  p_a: {
    position: 'absolute'
  },
  p_r: {
    position: 'relative'
  }
}
