import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import HiddenField from './components/HiddenField'
import Checkbox from './components/Checkbox'
import Valid from './helpers/validations'
import Axios from 'axios'
import Helmet from 'react-helmet'
// import { queryCache } from 'react-query'
import { AA, AS } from 'reducers/app'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

const CreateEmail = {
	auth: 'guest',
	onSubmit: async (values, instance, cancelToken, dispatch) => {
		const urlParams = new URLSearchParams(window.location.search)
		const key = urlParams.get('key')

		if (!key)
			return [{ id: 'errorRegKey', message:"A valid code is required to create an account." }]
		if (values.email !== values['confirm-email'])
			return [{id:'noMatch', message:"The emails do not match."}]
		if (values.password !== values.confirm)
			return [{id:'noMatch', message:"The passwords do not match."}]
		// const params = new URLSearchParams();
		// params.append('param1', 'value1');
		let { data, status } = await Axios.post(process.env.REACT_APP_API_URL + `/auth/local/register?key=${key}`, values, {
			cancelToken,
			validateStatus: function (status) { return status < 500; /* Resolve only if the status code is less than 500 */ }
		})
		if (status === 200)
		{
			if (!data?.success && data?.userUuid) {
				dispatch(AA.setState({tokens:data?.tokens, user:data?.userUuid, setup:true, loggedIn:true}))
				return [null, data, '/user/verify']
			} else {
				return [{ id: 'errorReg', message: "There was an error registering."}]
			}
		} else {
			if (status === 401)
			{
				console.log('data?.success !== undefined && data?.error', data?.success !== undefined, data?.error)
				if (data?.success !== undefined && data?.error)
					return [{id:"errorCreate", message:data?.error}]
				else
					return [{id:"errorCreate", message:"There was an error creating your account."}]
			}
		}
	},
	bg:BG.HIKERS,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Register to login through ChariTrek with your email.</h1>
				</div>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Create Account - ChariTrek</title>
			</Helmet>
			{/* <h1>Reset your password</h1> */}
			<Form>
				<InputField field="name" placeholder="Name" required />
				<InputField field="email" placeholder="Contact E-mail" type="email" required />
				<InputField field="confirm-email" placeholder="Confirm Contact E-mail" type="email" required />
				<div className="flexH">
					<div className="half">
						<InputField field="password" type="password" placeholder="Password" {...Valid.password} required />
					</div>
					<div className="half">
						<InputField field="confirm" type="password" placeholder="Confirm Password" {...Valid.password} required />
					</div>
				</div>
				<InputField mask='00/00/0000' field="dob" placeholder="MM/DD/YYYY" type="tel" {...Valid.date} required />
				<InputField mask='00000[-0000]' field="zipcode" placeholder="Postal Code" type="tel" {...Valid.postal} required />
				<HiddenField field="_csrf" defaultValue="1234" />
				<Checkbox field='agree-to-terms' validate={(v) => !v ? "Required" : false} >
					<p style={{textAlign:'left', marginTop:'16px', userSelect:'none'}}>Agree to <Link to='/legal/terms' target="_blank">Terms & Conditions</Link> and have read the <Link to='/legal/privacy' target="_blank">Privacy Policy</Link></p>
				</Checkbox>
				<div>
					<button type="submit" disabled={isSubmitting || !canSubmit}>
						{isSubmitting ? "Creating account..." : "CREATE ACCOUNT"}
					</button>
				</div>
			</Form>
		</div>
	)
}

export default CreateEmail
