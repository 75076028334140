import React, { memo, useState } from 'react'
import "./Play.scss"
import LocalizedText from 'data/LocalizedText'
import { toNumericalDecimal } from 'utils/string'
import { setState } from 'reducers/app'
import GP from "assets/google_play.svg"
import AS from "assets/apple_store.svg"
import CT_CTA from "assets/charitrek_cta.svg"
import Mobile from "utils/mobile"

const Play = memo((props) => {
	const trek = props?.trek?.[0] ?? {}
	const tax = trek?.price ? trek?.price * 0.087 : 0
	const total = trek?.price ? trek.price + tax : 0
	const [desktop] = useState(!Mobile.any())
	// console.log('trek', trek)
	return (
		<div className='modal-content play-modal'>
			<div className='trek-content'>
				<div className='trek-content_image' style={{backgroundImage:`url("${trek?.promotional_photos?.[0]?.uri_image_med}")`}} />
				<div className='trek-content_overview'>
					<div>
						{ (desktop) && <div className='play-modal--content_image' style={{width: 150, height:150, 'backgroundImage':`url('${process.env.REACT_APP_API_URL}/t/id/${trek.shortid}/qr?color=666666&bg=00000000')`}} /> }
					</div>
					<div className='play-modal--content_text'>
						{(!desktop) && <a className="open-cta" href={`charitrek://`} target="_blank" rel="noopener noreferrer">
							<img src={CT_CTA} alt="Open with ChariTrek"/>
						</a>}
						{ (desktop)
							? <h5>Scan the QR Code with your phone to begin your trek. Have fun and be safe!</h5>
							: null
						}
					</div>
				</div>
			</div>
			<div className='play-modal--content'>
				<div className='play-modal--content_details'>
					{ (desktop)
						? <h5 style={{width:'100%'}}>Don't have the app? Download it here:</h5>
						: <h5 style={{width:'100%'}}>Missing the app?<br/>Get it here:</h5>
					}
					<div className='links'>
						{(desktop || Mobile.android()) && <a href={`https://play.google.com/store/apps/details?id=host.exp.exponent`} target="_blank" rel="noopener noreferrer">
							<img src={GP} alt="Google Play Store"/>
						</a>}
						{(desktop || Mobile.ios()) && <a href={`https://itunes.apple.com/us/app/expo-client/id982107779?mt=8`} target="_blank" rel="noopener noreferrer">
							<img src={AS} alt="Apple App Store"/>
						</a>}
					</div>
				</div>
			</div>
		</div>
	)
})

export default Play
