import React, { useEffect, useState, memo} from 'react'
import { globalStyle as GS, globalFunc as GF, Colors } from '../global/styles'
import "./StarReviewIcons.scss"

const ReviewIcon = memo((props) => {

	// const [highlighted] = useState(new Array(parseInt(props.value)).fill(0))

	const [highlighted, setHighlighted] = useState([])
	const [base] = useState(new Array(5).fill(0))
	// const value = parseInt(props.value, 10)
	// const value = parseInt(parseInt(Math.random()*3), 10)

	useEffect(() => {
		// console.log('highlighted', props.value, highlighted, highlighted.length, parseInt(props.value))
		// let reviews = []
		// const g = {
		// 	full: String.fromCharCode(0xf005),
		// 	half: String.fromCharCode(0xf5c0),
		// }
		// let p = Math.min(parseFloat(i).toFixed(1), 5)
		// let f = parseInt(p) // full stars
		// if (p % 1 > 0.5) { reviews.push(0xf5c0) }
		// for (let i = 0; i < f; i++) {
		// 	reviews.push(0xf005)
		// }
		const value = parseFloat(props.value).toFixed(1)
		const half = value % 1 >= 0.5
		// console.log('value', parseInt(value))
		const h = new Array(parseInt(value)).fill(0xf810)
		if (half)
			h.unshift(0xf811)
		h.reverse()
		setHighlighted(h)
		// console.log('h', h)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value, setHighlighted])

	// useEffect(() => {
	// 	console.log("base", base, props.value)
	// }, [base, props.value])
	return (
		<span className={"starReviewIcon"}>
			{/* <span>{parseFloat(props.value, 10).toFixed(1)}</span> */}
			<span className="highlighted">
				{ highlighted.map((i, idx) => i && <i key={`star-${idx}`} className='icon ct-i'>{String.fromCharCode(i)}</i> )}
			</span>
			<span className="default">
				{ base.map((i, idx) => <i key={`star-default-${idx}`} className='icon ct-i'>{String.fromCharCode(0xf810)}</i> )}
			</span>
		</span>
	)
})

export default ReviewIcon
