import React, { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useField, splitFormProps } from 'react-form'
import { useRouteMatch } from 'react-router-dom'

const Input = memo(props => <input type="hidden" {...props} />);

Input.defaultProps = {
  value: ''
};

Input.propTypes = {
  value: PropTypes.string
};

export default memo(forwardRef((props, ref) => {
	let resetMatch = useRouteMatch("/auth/local/reset/:token")
  // Let's use splitFormProps to get form-specific props
	const [field, fieldOptions, rest] = splitFormProps({...props, defaultValue:resetMatch?.params?.token});

	// console.log('field', field, fieldOptions)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField(field, fieldOptions);

  // Build the field
  return (
    <>
      <Input {...getInputProps({ ref, ...rest })} />{" "}
    </>
  );
}))
