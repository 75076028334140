import React, { useState, useEffect } from 'react'
import "./ViewCard.scss"
import { axios } from 'utils/axios'
import CARD_AMEX from 'assets/cards/amex.svg'
import CARD_DINERS from 'assets/cards/diners.svg'
import CARD_DISCOVER from 'assets/cards/discover.svg'
import CARD_JCB from 'assets/cards/jcb.svg'
import CARD_MASTERCARD from 'assets/cards/mastercard.svg'
import CARD_UNIONPAY from 'assets/cards/unionpay.svg'
import CARD_VISA from 'assets/cards/visa.svg'

const CARD = {
	amex:CARD_AMEX,
	diners:CARD_DINERS,
	discover:CARD_DISCOVER,
	jcb:CARD_JCB,
	mastercard:CARD_MASTERCARD,
	unionpay:CARD_UNIONPAY,
	visa:CARD_VISA,
}

function ViewCard(props) {
	const brand = props.brand
	const exp_month = props.exp_month
	const exp_year = props.exp_year
	const last4 = props.last4
	const zip = props.zip
	const id = props.id

	const [remove, setRemove] = useState(false)
	const questionRemoval = () => {
		setRemove(true)
	}
	const confirmRemove = () => {
		axios.post(process.env.REACT_APP_API_URL + "/account/remove-payment", {
			id
		}, {
			_auth: true
		}).then(res => {
			console.log('cleanup response', res)
			// setClientSecret(res.data.clientSecret);
			props.onRemoveCard()
		})
	}
	const cancelRemove = () => { setRemove(false) }

	useEffect(() => {
		setRemove(false)
	}, [props])

	if (brand ?? exp_month ?? exp_year ?? last4) {
		return (
			<div className={`viewCard ${props.selected && !remove ? 'selected' : ''}`} >
				{ remove
				? (<div className='card-confirm'>
						<p>Are you sure you want<br/>to remove this card?</p>
						<div className='buttons'>
							<button onClick={confirmRemove}><i className='icon ct-r'>&#xf140;</i></button>
							<button onClick={cancelRemove}><i className='icon ct-r'>&#xf109;</i></button>
						</div>
					</div>)

				: (<>
						<div className="card-container" onClick={props.onClick}>
							<p className='card-type'><img src={CARD[brand]} alt={`card-${brand}`}/></p>
							<div className='card-info'>
								<p className="card-title">{brand} •••• {last4}</p>
								<p>Expires: {exp_month}/{exp_year}</p>
								{zip && <p>Zip: {zip}</p>}
							</div>
						</div>
						<div className='remove-card' onClick={questionRemoval}>
							<i className='icon ct-s'>&#xf135;</i>
						</div>
					</>)
				}
			</div>
		)
	} else {
		return (<div className="viewCard add" onClick={props.onClick}>
			<p><i className='icon ct-s'>&#xf102;</i> Add Payment Method</p>
		</div>)
	}
}

export default ViewCard
