import React, { memo, useState } from 'react'
import Helmet from 'react-helmet'
import { Link, useLocation, Redirect } from 'react-router-dom'
import "./AccountSelect.scss"
import { ToastContainer, toast } from 'react-toastify'

export default memo(() => {
	const location = useLocation()
	// console.log('location', location)
	const urlParams = new URLSearchParams(location.search)
	// console.log('key', urlParams.get('key'))
	const key = urlParams.get('key')
	const [redirect, setRedirect] = useState()
	if (!key) {
		toast("The url provided is not correct. Please try again from the email provided.", { toastId:'wrongUrl'})
		// setRedirect('/login')
	}
	return (
			<div className='account-type' style={{display:'flex', flexDirection:'column'}}>
				{ key
				?	<>
						<h3>Select Your Login Preference</h3>
						<div className='account-type--links'>
							<Link to={`/auth/local?key=${key}&next=${encodeURIComponent('/auth/setup')}`}>
								<button className="btn-create"><i className='icon ct-s'>&#xf10d;</i>Email</button>
							</Link>
							<a href={`/auth/facebook?key=${key}&next=${encodeURIComponent('/auth/setup')}`}>
								<button className="btn-create"><i className='icon ct-s'>&#xf111;</i>Facebook</button>
							</a>
							<a href={`/auth/google?key=${key}&next=${encodeURIComponent('/auth/setup')}`}>
								<button className="btn-create"><i className='icon ct-s'>&#xf113;</i>Google</button>
							</a>
							<a href={`/auth/twitter?key=${key}&next=${encodeURIComponent('/auth/setup')}`}>
								<button className="btn-create"><i className='icon ct-s'>&#xf136;</i>Twitter</button>
							</a>
						</div>
					</>
				: <>
					<h3>Sorry, Use your email provided with your unique code to create an account.</h3>
				</>}
			</div>
	)
})
