import React, { useState, useCallback, useEffect } from 'react'
import { CardElement, useStripe, useElements, getClientSecret } from "@stripe/react-stripe-js";
import ViewCard from './cards/ViewCard'
import AddCard from './cards/AddCard'
import "./PaymentSetupForm.scss"
import { axios } from 'utils/axios'
import Spinner from 'components/Spinner'
import Paypal from './Paypal'

function PaymentSetupForm(props) {
	const [loading, setLoading] = useState(true)
	const [selected, setSelected] = useState(0)
	const [adding, setAdding] = useState(false)
	const {trek} = props
	const stripe = useStripe();
	const elements = useElements();

	const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

	const [cards, setCards] = useState([
		// { card:{ brand:"Mast", exp_month:"04", exp_year:"2023", last4:"2345" }, zip:'99206' },
		// { card:{ brand:"Amex", exp_month:"01", exp_year:"2023", last4:"1234" }, zip:'99206' },
		// { card:{ brand:"Visa", exp_month:"08", exp_year:"2023", last4:"7542" }, zip:'99206' },
	])

	const refreshPayments = () => (
		axios.get(process.env.REACT_APP_API_URL + "/account/retrieve-payments", {
			_auth: true
		}).then(res => {
			const { paymentMethods } = res.data
			const newCards = paymentMethods.data.map(p => ({ id:p.id, card:p.card, zip: p.billing_details.address.postal_code }))
			console.log('data', newCards)
			setCards(newCards)
			// setClientSecret(res.data.clientSecret);
		})
	)

	/* useEffect(() => {
		axios.post(process.env.REACT_APP_API_URL + "/account/paypal/create-order", {}, {
			_auth: true
		}).then(res => {
			console.log("res", res)
			// const { paymentMethods } = res.data
			// const newCards = paymentMethods.data.map(p => ({ id:p.id, card:p.card, zip: p.billing_details.address.postal_code }))
			// console.log('data', newCards)
			// setCards(newCards)
			// setClientSecret(res.data.clientSecret);
		})
	}, []) */

	useEffect(() => {
    // Create PaymentIntent as soon as the page loads
		refreshPayments().then(() => {
			setLoading(false)
		})

    // window
    //   .fetch("/account/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({trek: [{ id: "xl-tshirt" }]})
    //   })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //     setClientSecret(data.clientSecret);
    //   });
  }, []);

	const selectCard = index => {
		console.log('click', index)
		setSelected(index)
	}

	const removeCard = index => {
		console.log('Removing card:', index)
		const newCards = [...cards]
		newCards.splice(index, 1)
		setCards(newCards)
	}

	const addCard = () => {
		console.log('adding new payment.')
		setAdding(true)
	}

	const onAddSucceeded = () => {
		refreshPayments().then(() => {
			setAdding(false);
		})
	}

	const cancelAdd = () => {
		setAdding(false);
	}

	const makePayment = () => {
		console.log('making a payment with card', cards[selected], trek.uuid)
		setProcessing(true)
		setError(undefined)
		// setTimeout(() => {
		// 	setError("There was an error. Unable to process charge.")
		// 	setProcessing(false)
		// }, 5000)
		axios.post(process.env.REACT_APP_API_URL + "/account/card/charge-payment", {
			paymentId: cards[selected]?.id,
			trek: trek.uuid
		}, {
			_auth: true
		})
		.then(res => {
			props.onPaymentComplete()
		})
		.catch(e => {
			console.log("e", e)
			setError("There was an error. Unable to process charge.")
			setProcessing(false)
		})
	}

	return ( loading
		? <div className="paymentSetupForm loader">
				<Spinner />
			</div>
		: <div className="paymentSetupForm">
				<div className="cardSelection">
					{ cards.map(({card, zip, id}, index) => (
						<ViewCard key={`viewcard-${index}`}
							selected={selected === index}
							{...card}
							zip={zip}
							id={id}
							onRemoveCard={() => removeCard(index)}
							onClick={() => selectCard(index)} />
					))}
					{ cards.length < 4 && (
							!adding
							? <ViewCard onClick={addCard} />
							: <AddCard onRemoveCard={cancelAdd} onSucceeded={onAddSucceeded} />
						)
					}
				</div>
				{ cards.length === 0
					? ''
					: <div className="payment-charge">
							{error && (
								<div className="payment-error" role="alert">
									{error}
								</div>
							)}
							<button className="payment-card" disabled={ processing } onClick={makePayment}>{
								processing
								? <Spinner />
								: `Pay With Card **${cards[selected]?.card?.last4}`
							}</button>
						</div>
				}
				<p>or:</p>
				<Paypal trek={trek} />
				{/* <button className="payment-paypal" disabled={ processing }>PayPal</button> */}
			</div>
	)
}

export default PaymentSetupForm
