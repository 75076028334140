import React from 'react'
import AppHome from './AppHome'
import NoMatch from 'pages/NoMatch'
import {
  // BrowserRouter as Router,
  Switch,
	Route,
	Redirect,
	// Link,
	// useParams,
	// useRouteMatch,
	useLocation,
	useHistory,
} from "react-router-dom";


const AppIndex = () => {
	return (
		<div>
			<Switch>
				<Route exact path='/browse' component={AppHome} />
				<Route exact path='/search' component={AppHome} />
				<Route exact path='/browse/saved' component={AppHome} />
				<Route exact path='/browse/owned' component={AppHome} />
				<Route exact path='/info' component={AppHome} />
				<Route exact path='/account' component={AppHome} />
				{/* <Route exact path='/account' component={AppIndex} /> */}
				<Route exact path='/t/id/:uuid' component={AppHome} />
				<Route exact path='/t/id/:uuid/dl' component={AppHome} />
				<Route path="*">
					<NoMatch />
				</Route>
			</Switch>
		</div>
	)
}

export default AppIndex
