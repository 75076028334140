import React, { useState, memo, useEffect, useCallback, useContext } from 'react'
import LocalizedText from 'data/LocalizedText'
import ReviewIcon from 'components/ReviewIcon'
import StarReviewIcons from 'components/StarReviewIcons'
import { useQueryClient } from 'react-query'
import './TrekTileList.scss'
import { toNumericalDecimal } from 'utils/string'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserContext } from 'contexts/userContext'
import { PurchaseContext, purchaseState, showPurchase, hidePurchase } from 'contexts/purchaseContext'

const TrekListTile = memo((props) => {
	const trek = props.trek
	const [purchased, setPurchased] = useState(false)
	const [favorited, setFavorited] = useState(false)
	const { showPurchase, showInstructions } = useContext(PurchaseContext)

	const shareEvent = useCallback(
		async () => {
			console.log("share event")
			const url = `${window.location.origin}/t/${trek.uuid}`
			if (navigator.share) {
				navigator.share({
					title: 'WebShare API Demo',
					url
				}).then(() => {
					console.log('Thanks for sharing!');
				})
				.catch(console.error);
			} else {
				if (navigator.clipboard) {
					// copy text TO the clipboard
					await navigator.clipboard.writeText(url);
				}
				toast.warn("The URL to share has been copied. Paste it to share.", {
					toastId: "shareURL",
					autoClose: 4000,
					pauseOnFocusLoss:true
				})
			}
		},
		[trek],
	)

	const {user:me} = useContext(UserContext)
			// console.log('me', me)

	useEffect(() => {
		if (me && trek) {
			const trekPurchased = me.treks_purchased.filter(tp => tp.trek_uuid === trek.uuid)[0]
			const trekFavorited = me.treks_favorited.filter(tf => tf.trekUuid === trek.uuid)[0]
			console.log('trekPurchased', trekPurchased)
			// console.log('me', me)
			setPurchased(trekPurchased)
			setFavorited(trekFavorited)
		}
	}, [me, trek])

	const purchaseEvent = useCallback(
		async () => {
			console.log("purchase event")
			// const url = `${window.location.origin}/t/${trek.uuid}`
			console.log('trek', trek)
			showPurchase(trek.uuid)
		},
		[trek, showPurchase],
	)
	const instructionEvent = useCallback(
		async () => {
			console.log("purchase event")
			// const url = `${window.location.origin}/t/${trek.uuid}`
			console.log('trek', trek)
			showInstructions(trek.uuid)
		},
		[trek, showInstructions],
	)

	return (
		<div key={`trek-${trek.uuid}`} className='trekTile trekTile-container'>
			<Link to={`/t/id/${trek.uuid}`} className={`trek-link`}>
				<div/>
			</Link>
			<div className='promo-image' style={{backgroundImage:`url("${trek.promotional_photos?.[0]?.uri_image_small}")`}}/>
			<div className='label'>
				<h5 className='title'>{new LocalizedText(trek.title).text}</h5>
				<h5 className='version'>Version {trek.version}</h5>
			</div>
			<div className='spacer'/>
			<div className='card-data'>
				<div className="bg-grey">
					<div className="review">
						<ReviewIcon value={trek.rating?.difficult ?? 0} type="dif" />
						<ReviewIcon value={trek.rating?.educational ?? 0} type="edu" />
						<ReviewIcon value={trek.rating?.fun ?? 0} type="fun" />
					</div>
					<div className='spacer'/>
					<div className="purchase-area">
						<Link to={'#'} onClick={e => {
							console.log('bookmark')
						}}>
							<i className={`icon ${favorited ? 'ct-s' : 'ct-r'}`}>&#xf106;</i>
						</Link>
						<Link to={'#'} onClick={shareEvent}>
							<i className="icon ct-r">&#xf12e;</i>
						</Link>
						{ purchased
						? <Link className='price' to={'#'} onClick={instructionEvent}>
								<div>PLAY</div>
							</Link>
						: <Link className='price' to={'#'} onClick={purchaseEvent}>
								<div >{trek.price === 0 ? "FREE" : `$${toNumericalDecimal(trek?.price)}`}</div>
							</Link>
						}
					</div>
				</div>
				<h5 className="overall-reviews"><StarReviewIcons value={trek.rating?.overall ?? 0} /> {trek.rating?.reviews} Reviews</h5>
			</div>
		</div>
	)
})

export default TrekListTile
