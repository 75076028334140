import React from 'react'
import { Helmet } from "react-helmet"
import { Link, useLocation } from 'react-router-dom'
import axios from "axios"
import { useQuery } from "react-query"

function Home() {
	const {pathname} = useLocation()
	const { status, data, error, isFetching } = useQuery(pathname, async () => {
		const { data } = await axios.get(process.env.REACT_APP_API_URL + pathname);
	  return data;
	}, {
		staleTime: 2 * 60 * 1000, // 2 min
		initialData:() => window.__ct?.INITIAL_PATH === pathname ? window.__ct?.SERVER_DATA : undefined
	});

	console.log('data', status, data, error, isFetching)
	// console.log('window.INITIAL_PATH == pathname', window.INITIAL_PATH == pathname)

	return (
		<div className="App-header">
			<Helmet>
				<title>Test - ChariTrek</title>
			</Helmet>
			<p>
				ChariTrek Alpha { !isFetching && data.someString }
			</p>
			<Link to='/'> Home </Link>
		</div>
	)
}

export default Home
