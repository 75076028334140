import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import GP from "../../assets/google_play.svg"
import AS from "../../assets/apple_store.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Helmet from 'react-helmet'
// import { queryCache } from 'react-query'
import { useHistory } from 'react-router-dom'

const isMobile = {
  Android: function () { return navigator.userAgent.match(/Android/i); },
  BlackBerry: function () { return navigator.userAgent.match(/BlackBerry/i); },
  iOS: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
  Opera: function () { return navigator.userAgent.match(/Opera Mini/i); },
  Windows: function () { return navigator.userAgent.match(/IEMobile/i); },
  any: function () { return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()); }
};
const GetStarted = {
	auth: true,
	bg:BG.STREET,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>You're all set!</h1>
				</div>
				<p>Download the app for your phone to get started!</p>
			</div>
		</div>
	),
	formArea: ({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Welcome - ChariTrek</title>
			</Helmet>
			<Link to='/browse'>
				<h1 style={{
					marginBottom:'10px',
					color:'white',
					display:'flex',
					flexFlow:'row nowrap',
					alignItems:'center',
					justifyContent:'center',
				}}><i className='icon ct-r' style={{fontSize:'1em'}}>&#xf107;</i>&nbsp;Start Browsing</h1>
			</Link>
			<h4 style={{marginBottom:'10px'}}>or</h4>
			<h1 style={{marginBottom:'20px'}}>Get the App:</h1>
			<div className='social-icons' style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
				{ !isMobile.iOS() && <a href={`https://play.google.com/store/apps/details?id=host.exp.exponent`} target="_blank" rel="noopener noreferrer">
					<img style={{width:'150px'}} src={GP} alt="Google Play Store"/>
				</a> }
				{ !isMobile.Android() && <a href={`https://itunes.apple.com/us/app/expo-client/id982107779?mt=8`} target="_blank" rel="noopener noreferrer">
					<img style={{width:'150px', marginTop:'20px'}} src={AS} alt="Apple App Store"/>
				</a> }
			</div>
		</div>
	)
}
export default GetStarted
