import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Checkbox from './components/Checkbox'
import Axios from 'axios';
import Valid from './helpers/validations'
import Helmet from 'react-helmet'
// import { queryCache } from 'react-query'
import { actions as AA } from 'reducers/app'
import { sendLogin } from 'utils/axios'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

const styles = {
	trouble: {
		color: 'white',
		alignSelf: 'flex-end',
		fontSize: '0.9rem',
		margin: 5
	}
}
let attempts = 0
let toAttempt

const Login = {
	auth: 'guest',
	onSubmit:async (values, instance, cancelToken, dispatch) => {
		console.log('values', values)
		try {
			let data = await sendLogin(values)
			return [null, data, '/alpha/get-started']
		} catch(error) {
			console.log('login error:', error)
			return [error]
		}
		// requestLogin(requestLogin)
		/* attempts++
		console.log('values', values)
		try {
			if (attempts < 6) {
				const next = false
				// await new Promise(resolve => setTimeout(resolve, 100000));
				let res = await Axios.post(process.env.REACT_APP_API_URL + `/auth/local${(next ? `?next=${next}` : '')}`, values, {
					cancelToken,
					validateStatus: function (status) { return status < 500; }, // Resolve only if the status code is less than 500 *
				})
				console.log('res', res)
				if (res.status === 200)
				{
					// dispatch(AA.setState({tokens:res.data?.tokens, user:res.data?.userUuid, loggedIn:true}))
					// dispatch({type:"SAGA_ME"})
					// return [null, res.data]
				} else if (res.status === 429) {
					return [{id:"tooMany", message:"Too many attempts. Please try again in a minute."}]
				} else {
					return [{ id: "invalidLogin", message: "Unable to login. \n\r\nPlease check your username and/or password" }]
				}
			} else {
				if (!toAttempt) {
					toAttempt = setTimeout(() => {
						attempts = 0
						toAttempt = undefined
					}, 30000)
				}
				return [{id:"tooMany", message:"Too many attempts. Please try again in 30 seconds."}]
			}
		} catch(e) {
			console.log('e', e)
			return [{ id: "invalidLogin", message: "Unable to login. \n\r\nPlease check your username and/or password" }]
		} */
	},
	bg:BG.AIRPLANE,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Login to ChariTrek<sup>®</sup> and find your next adventure!</h1>
				</div>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Login - ChariTrek</title>
			</Helmet>
			 {/* {loggedIn ? <Redirect to="/dashboard" /> : <PublicHomePage />} */}
			<div style={{flexGrow:1}} />
			<Form>
				<InputField field="identifier" placeholder="E-mail" type='email' required />
				<InputField field="password" type='password' placeholder="Password" {...Valid.password} required />
				<Checkbox field='remember' style={{marginTop:'8px'}}>
					<p style={{textAlign:'left', userSelect:'none'}}>Remember Me</p>
				</Checkbox>
				<div>
					<button type="submit" disabled={isSubmitting || !canSubmit}>
						{isSubmitting ? "LOGGING IN..." : "LOGIN"}
					</button>
				</div>
			</Form>
			<Link to="/auth/forgot" style={styles.trouble}>
				<p>Trouble logging in ?</p>
			</Link>
			<h1 style={{marginTop:'10px', marginBottom:'20px'}}>or Login with</h1>
			<div className='social-icons'>
				<a href="/auth/facebook">
					<i className='icon ct-s'>&#xf111;</i>
				</a>
				<a href="/auth/google">
					<i className='icon ct-s'>&#xf113;</i>
				</a>
				<a href="/auth/twitter">
					<i className='icon ct-s'>&#xf136;</i>
				</a>
			</div>
			<div style={{flexGrow:1}} />
			<Link to="/register">
				<h4 style={{marginTop:'40px'}}>Don't have an account? Register</h4>
			</Link>
		</div>
	)
}
export default Login
