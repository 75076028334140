import React, { memo, useEffect, useState } from 'react'
import ReviewEntry from './ReviewEntry'
import "./ReviewGroup.scss"
import ReviewIcon from 'components/ReviewIcon'
import StarReviewIcons from 'components/StarReviewIcons'

const ReviewGroup = memo((props) => {
	const trek = props.trek
	const [filter, setFilter] = useState('all')
	const setFilterAll = () => {
		setFilter('all')
	}
	const setFilterCurrent = () => {
		setFilter('current')
	}
	return (
		<div className='review-group'>
			<h4>Reviews</h4>
			{ trek?.reviewCount > 0
			?
				<div className='review-overall'>
					<div className='review-filter'>
						<button className={`${filter === 'all' ? 'active' : ''}`} onClick={setFilterAll}>All</button>
						<button className={`${filter === 'current' ? 'active' : ''}`} onClick={setFilterCurrent}>Current Version</button>
					</div>
					<div className='review-overall-summary'>
						<h4>{trek.rating.overall}</h4>
						<StarReviewIcons value={trek.rating.overall} />
						<p><i className='icon ct-r'>&#xf128;</i> { trek.reviewCount } total</p>
					</div>
					<div className='review-categories'>
						<ReviewIcon value={trek.rating.difficult} type="dif" />
						<ReviewIcon value={trek.rating.educational} type="edu" />
						<ReviewIcon value={trek.rating.fun} type="fun" />
					</div>
					<div className='reviews'>
						<div className='review-section'>
							<p>{5}</p>
							<div className='overall'>
								<div className='overall-highlight overall-5' style={{width: `${ trek.rating.overall_5/trek.reviewCount*100 }%`}}/>
							</div>
						</div>
						<div className='review-section'>
							<p>{4}</p>
							<div className='overall'>
								<div className='overall-highlight overall-4' style={{width: `${ trek.rating.overall_4/trek.reviewCount*100 }%`}}/>
							</div>
						</div>
						<div className='review-section'>
							<p>{3}</p>
							<div className='overall'>
								<div className='overall-highlight overall-3' style={{width: `${ trek.rating.overall_3/trek.reviewCount*100 }%`}}/>
							</div>
						</div>
						<div className='review-section'>
							<p>{2}</p>
							<div className='overall'>
								<div className='overall-highlight overall-2' style={{width: `${ trek.rating.overall_2/trek.reviewCount*100 }%`}}/>
							</div>
						</div>
						<div className='review-section'>
							<p>{1}</p>
							<div className='overall'>
								<div className='overall-highlight overall-1' style={{width: `${ trek.rating.overall_1/trek.reviewCount*100 }%`}}/>
							</div>
						</div>
					</div>
			</div>
			: <p>No reviews yet.</p> }
			{ trek?.reviews?.latest?.map((r, i) =>
				<ReviewEntry key={`review-${i}`} review={r} />
			)}
			{/* <p>{ JSON.stringify(trek?.reviews) } </p> */}
		</div>
	)
})

export default ReviewGroup
