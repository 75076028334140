import React, { memo, useState } from 'react'
import "./Instructions.scss"
import LocalizedText from 'data/LocalizedText'
import { toNumericalDecimal } from 'utils/string'
import { setState } from 'reducers/app'
import GP from "assets/google_play.svg"
import AS from "assets/apple_store.svg"
import CT_CTA from "assets/charitrek_cta.svg"
import Mobile from "utils/mobile"

const Instructions = memo((props) => {
	const trek = props?.trek?.[0] ?? {}
	const tax = trek?.price ? trek?.price * 0.087 : 0
	const total = trek?.price ? trek.price + tax : 0
	const [desktop] = useState(!Mobile.any())
	// console.log('trek', trek)
	console.log('desktop', desktop, Mobile.android, Mobile.ios)
	return (
		<div className='modal-content payment instructions'>
			<div className='payment-content'>
				<div className='payment-image' style={{backgroundImage:`url("${trek?.promotional_photos?.[0]?.uri_image_med}")`}} />
				<div className='payment-overview'>
					<div className='payment-content_details'>
						<div className='detail_row'>
							<h4>{new LocalizedText(trek.title).text}</h4>
							{trek.price === 0 ? <h4 className='price'>Free</h4> : (<h4 className='price'><span>$</span>{toNumericalDecimal(trek.price)}</h4>) }
						</div>
						<div className='detail_row'>
							<h4>Sales Tax</h4>
							{tax === 0 ? <h4 className='price'>Free</h4> : (<h4 className='price'><span>$</span>{toNumericalDecimal(tax)}</h4>) }
						</div>
					</div>
					<div className='bar'/>
					<div className='detail_row'>
						<h3>Total</h3>
						{total + tax === 0 ? <h3 className='price'>Free</h3> : (<h3 className='price'><span>$</span>{toNumericalDecimal(total)}</h3>) }
					</div>
				</div>
			</div>
			<div className='instructions-content'>
				<div className='instructions-content_header'>
					<div>
						{ (desktop) && <div className='instructions-image' style={{width: 150, height:150, 'backgroundImage':`url('${process.env.REACT_APP_API_URL}/t/id/${trek.shortid}/qr?color=666666&bg=00000000')`}} /> }
					</div>
					<div className='instructions-content_text'>
						<h2 className='instructions-content_title'>Purchase Complete!</h2>
						<br/>
						{(!desktop) && <a className="open-cta" href={`charitrek://`} target="_blank" rel="noopener noreferrer">
							<img src={CT_CTA} alt="Open with ChariTrek"/>
						</a>}
						<h4>Congratulations!</h4>
						{ (desktop)
							? <h5>Scan the QR Code with your phone to begin your trek. An email receipt of this purchase has also been sent to your email which also has the QR Code available. Have fun and be safe!</h5>
							: <h5>Click the link above to get started on your mobile device. If you have not installed the application use these links: </h5>
						}
					</div>
				</div>
				<div className='instructions-content_details'>
					{ (desktop)
						? <h5 className='break' style={{width:'100%'}}>Don't have the app? Download it here:</h5>
						: <h5 className='break' style={{width:'100%'}}>Missing the app?<br/>Get it here:</h5>
					}
					<div className='links'>
						{(desktop || Mobile.android()) && <a href={`https://play.google.com/store/apps/details?id=host.exp.exponent`} target="_blank" rel="noopener noreferrer">
							<img src={GP} alt="Google Play Store"/>
						</a>}
						{(desktop || Mobile.ios()) && <a href={`https://itunes.apple.com/us/app/expo-client/id982107779?mt=8`} target="_blank" rel="noopener noreferrer">
							<img src={AS} alt="Apple App Store"/>
						</a>}
					</div>
				</div>
			</div>
		</div>
	)
})

export default Instructions
