import React, { memo, useState, useEffect } from 'react'
import "./Payment.scss"
import LocalizedText from 'data/LocalizedText'
import { useForm, useField } from 'react-form'
import { toNumericalDecimal } from 'utils/string'
import InputField from 'pages/auth/components/InputField'
import SelectField from 'pages/auth/components/SelectField'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm"
import PaymentSetupForm from './PaymentSetupForm'
import Spinner from 'components/Spinner'

// console.log('process.env.REACT_APP_STRIPE_PB_KEY', process.env.REACT_APP_STRIPE_PB_KEY)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PB_KEY);

const Payment = memo((props) => {
	// const [loading, setLoading] = useState(true)
	// const [paymentSelect, setPaymentSelect] = useState('credit')
	const trek = props?.trek?.[0] ?? {}
	const tax = trek?.price ? trek?.price * (props.taxRate ?? 0) : 0
	const total = trek?.price ? trek.price + tax : 0

	/* const { Form, meta, reset, handleSubmit, formContext } = useForm({
		onSubmit: async (values, instance) => {
			// const { nearby, search, near, within } = values

			// history.push(`/search?${urlVars.toString()}`)
			console.log("value submit", values, instance)
		}
	}) */
	// const { value, setValue, meta: { error, isTouched, isValidating }, getInputProps } = useField("search", { validate: (value, instance) => value === '', defaultValue:search.get('q') ?? '', formContext })

	const months = [
		{value:0, label:"01 January"},
		{value:1, label:"02 February"},
		{value:2, label:"03 March"},
		{value:3, label:"04 April"},
		{value:4, label:"05 May"},
		{value:5, label:"06 June"},
		{value:6, label:"07 July"},
		{value:7, label:"08 August"},
		{value:8, label:"09 September"},
		{value:9, label:"10 October"},
		{value:10, label:"11 November"},
		{value:11, label:"12 December"},
	]
	const currentYear = new Date().getFullYear()
	let years = []
	for (let i = 0; i < 15; i++) {
		years.push({value:currentYear + i, label:currentYear + i})
	}

	// console.log('trek', trek)
	return (
		<div className='modal-content payment'>
			<div className='payment-content'>
				<div className='payment-image' style={{backgroundImage:`url("${trek?.promotional_photos?.[0]?.uri_image_med}")`}} />
				<div className='payment-overview'>
					<div className='payment-content_details'>
						<div className='detail_row'>
							<h4>{new LocalizedText(trek.title).text}</h4>
							{trek.price === 0 ? <h4 className='price'>Free</h4> : (<h4 className='price'><span>$</span>{toNumericalDecimal(trek.price)}</h4>) }
						</div>
						<div className='detail_row'>
							<h4>Sales Tax</h4>
							{tax === 0 ? <h4 className='price'>Free</h4> : (<h4 className='price'><span>$</span>{toNumericalDecimal(tax)}</h4>) }
						</div>
					</div>
					<div className='bar'/>
					<div className='detail_row'>
						<h3>Total</h3>
						{total + tax === 0 ? <h3 className='price'>Free</h3> : (<h3 className='price'><span>$</span>{toNumericalDecimal(total)}</h3>) }
					</div>
				</div>
			</div>
			<div className='payment-method'>
				{ trek.price === 0
					? <>
							<h1>Confirm purchase?</h1>
							<button style={{margin:'20px'}}>Purchase Trek</button>
						</>
					: <>
							<h1>Select a Payment</h1>
							<div className='payment-area'>
								<Elements stripe={stripePromise} options={{
									fonts:[{cssSrc:'https://use.typekit.net/ahc4yqu.css'}]
									}}>
									{/* <CheckoutForm trek={trek} /> */}
									<PaymentSetupForm trek={trek} onPaymentComplete={props.onNext}/>
								</Elements>
							</div>
						</>
				}
				<div className='payment-content_purchase' style={{marginTop:10}}>
					{/* <button onClick={props?.onNext}>Purchase</button> */}
					<br/>
					<div style={{marginTop:10}}><i className='icon ct-s'>&#xf11c;</i> Secure Transaction</div>
				</div>
			</div>
		</div>
	)
})

export default Payment
