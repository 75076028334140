import React, { memo, useState, useEffect, useRef, useCallback } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useLocation, Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { default as Create } from './create'
import { default as CreateEmail } from './createEmail'
import { default as Login } from './login'
import { default as Setup } from './setup'
import { default as Signup } from './signup'
import { default as Forgot } from './pwForgot'
import { default as Reset } from './pwReset'
import { default as getStarted } from './getStarted'
import { default as SignupThanks } from './signupThanks'
import { default as Verify } from './userVerify'
import { default as Page, BG, EMAIL_REGEX } from './Page'
import { Footer } from "../layout"
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import { ReactComponent as Logo } from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import Axios from 'axios'
// import { queryCache } from 'react-query'
import { useToken } from 'utils/axios'
import NoMatch from '../NoMatch'
import { useDispatch, useSelector } from 'react-redux'
import { AA, AS } from 'reducers/app'
import { tokenExpired } from 'utils/axios'

async function sendToFakeServer (values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

export default memo(function AuthPage(props) {
	const { user, setup, verified } = useSelector(state => state.app)
	const location = useLocation();
	const data = useRef()
	const apiCancel = useRef()
	const history = useHistory();
	const resetMatch = useRouteMatch("/auth/local/reset/:token")
	const dispatch = useDispatch()
	const token = useToken()

	// console.log('token', token)

	// console.log("index location", location)
	// let sp = new URLSearchParams(location.search)
	// console.log("sp", sp.get('key'), user)

	// const [redirect, setRedirect] = useState()
	const [urlq] = useState(new URLSearchParams(location.search))
	// console.log('urlq', urlq, urlq.get('key'))

	const {
	  Form,
		meta,
		reset
	} = useForm({
		onSubmit: async (values, instance) => {
			try {
				// console.log("instance.meta", instance.meta)
				// if (instance.meta.isSubmitting) {
				  // console.log("already submitting")
				  // throw new Error('already submitting');
				// }
				// onSubmit (and everything else in React Form)
				// has async support out-of-the-box
				apiCancel.current = Axios.CancelToken.source();
				let [error, res, redirect] = await data.current.onSubmit(values, instance, apiCancel.current.token, dispatch);
				console.log("Return from onSubmit", error?.message, error?.code, res, redirect)
				if (error)
				{
					if (error?.message) {
						toast.error(error?.message, {
							toastId: error?.code
						})
					}
				} else {
					if (res?.success)
						toast(res?.success)
					else if (res)
						toast("Success")
					// console.log("Huzzah!", res);
				}
				// const next = urlq.get('next')
				// console.log("redirect check:", next, redirect)
				// urlq.delete('next')
				// if (next && !error) {
					// setTimeout(() => dispatch({type:"SAGA_REDIRECT", path:`${next}?${urlq.toString()}`}), 500)
					// setTimeout(() => {
					// 	window.location.href = next
					// }, 500)
				if (redirect) {
						history.push(redirect)
				}
			} catch(e) {
				console.log("e", e)
				toast.error("There was an error connecting to server.", {
					toastId: "serverError"
				})
			}
	  }
	});

	useEffect(() => {
		// console.log("index effect.", user, location.pathname, location.state)
		if (location.pathname === '/register')
			data.current = Signup
		else if (location.pathname === '/login')
			data.current = Login
		else if (location.pathname === '/auth/forgot')
	    data.current = Forgot
	  else if (resetMatch?.isExact)
	    data.current = Reset
	  else if (location.pathname === '/auth/create')
	    data.current = Create
	  else if (location.pathname === '/auth/local')
	    data.current = CreateEmail
	  else if (location.pathname === '/alpha/thanks')
	    data.current = SignupThanks
	  else if (location.pathname === '/auth/setup')
			data.current = Setup
		else if (location.pathname === '/alpha/get-started')
			data.current = getStarted
		else if (location.pathname === '/user/verify')
			data.current = Verify
		else
			data.current = undefined

		if (!!data.current) {
			// console.log({loggedIn, pathname:location.pathname, next:urlq.get('next'), key:urlq.get('key')})
			if (data.current?.auth === true && !token) {
				// console.log('window.__ct.FLASH?.data?.tokens', window.__ct.FLASH?.data?.tokens, window.__ct.FLASH?.data?.user)
				if (window.__ct.FLASH?.data?.tokens && window.__ct.FLASH?.data?.userUuid) {
					dispatch(AA.setState({tokens:window.__ct.FLASH.data.tokens, user:window.__ct.FLASH.data.userUuid, loggedIn:true}))
					dispatch({type:"SAGA_ME", tokens:window.__ct.FLASH.data.tokens})
				} else
					history.replace('/login')
			} else if (token && location.pathname === '/login' && urlq.get('next') && urlq.get('key')) {
				const next = urlq.get('next')
				urlq.delete('next')
				history.replace(`${next}?${urlq.toString()}`)
			} else if ((data.current?.auth === true || data.current?.auth === 'guest') && !tokenExpired(token)) {
				if (!setup && location.pathname !== '/auth/setup')
					history.replace('/auth/setup')
				else if (setup && !verified && location.pathname !== '/user/verify')
					history.replace('/user/verify')
				else if (setup && verified && location.pathname !== '/alpha/get-started')
					history.replace('/profile')
			}
		}

		if (apiCancel.current?.cancel)
			apiCancel.current.cancel()
		reset()
		let d = data?.current?.mount?.(dispatch)
	// eslint-disable-next-line
	}, [location.pathname, reset, user, token, setup, verified])

	const runOnce = useRef(false)
	useEffect(() => {
		if (location.pathname === '/user/verify' && !runOnce.current) {
			dispatch({type: "SAGA_VERIFY", key: urlq.get('key')})
			runOnce.current = true
		} else if (location.pathname === '/auth/setup') {
			dispatch({type:"SAGA_ME"})
		}
		return () => {
			// cleanup
			// clearInterval(toUserCheck.current)
		}
	}, [dispatch, location.pathname, urlq])

	// console.log('redirect', redirect)

	/* useEffect(() => {
		console.log('window.__ct.INITIAL_PATH', window.__ct.INITIAL_PATH, location.pathname)
		if (window.__ct.SERVER_STATUS === 401 && window.__ct.INITIAL_PATH === location.pathname) {
			if (window.__ct?.FLASH?.error) {
				toast.error(window.__ct?.FLASH?.error, {
					toastId: "serverErrorFlash"
				})
			}
			history.push('/login')
		}
	// eslint-disable-next-line
	}, []) */

	if (!data.current)
		return <NoMatch />
	return (
		<div>
			{/* { redirect && <Redirect to={redirect} /> } */}
			<Page bg={data.current.bg}
				photoArea={data.current.photoArea}
				formArea={data.current.formArea?.({Form, meta})}
			/>
			<Footer />
		</div>
	)
})
