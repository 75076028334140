import React from 'react'
import "./Signin.scss"

function Signin() {
	return (
		<div className='modal-content signin'>
			<h2 className='title'>Sign in to your account</h2>
			<div className='row signin-fields'>
				<div className='modal-input'>
					<label>Email Address</label>
					<input type="email" name='email' placeholder='Enter Email Address' />
				</div>
				<div className='modal-input password'>
					<label>Password</label>
					<input type="password" name='password' placeholder='Enter Password' />
					<a href='/forgot'>Forget your Password?</a>
				</div>
			</div>
			<br/>
			<button>Sign in</button>
			<div>
			<h5 className='break' style={{width:'100%'}}>Don't have an account?</h5>
			<br/>
			<button>Create Account</button>
			</div>
		</div>
	)
}

export default Signin
