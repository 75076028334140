import React, { useState } from 'react'
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import "./Paypal.scss"
import { axios } from 'utils/axios'

const initialOptions = {
	"client-id": "sb", //"ATffOS6BwS0v3AI7D6DpMs0clhtsqpJu7GscX6FJsl6pI2ln7ZZY9Q3UcpOAA4OtcxRqQQWgU2JDygTV",
	// currency: "USD",
	// intent: "capture",
	// "data-client-token": "abc123xyz==",
};

function Paypal(props) {
	const { trek } = props
	const [amount, setAmount] = useState("2.00");
	const [orderID, setOrderID] = useState("");
	const [onApproveMessage, setOnApproveMessage] = useState("");
	const [onErrorMessage, setOnErrorMessage] = useState("");

	const createOrder = (data, actions) => (
		axios.post(process.env.REACT_APP_API_URL + "/account/paypal/create-order", { trek:trek.uuid }, { _auth:true })
			.then(function({data:orderData}) {
				console.log('orderData', orderData)
				return orderData.orderID
			})
	)

	const onApprove = (data, actions) => (
		axios.post(process.env.REACT_APP_API_URL + "/account/paypal/capture-order", { order:data.orderID, trek:trek.uuid }, { _auth:true })
		.then(function({data:orderData}) {
			// Three cases to handle:
			//   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
			//   (2) Other non-recoverable errors -> Show a failure message
			//   (3) Successful transaction -> Show confirmation or thank you

			// This example reads a v2/checkout/orders capture response, propagated from the server
			// You could use a different API or structure for your 'orderData'
			var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

			if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
					return actions.restart(); // Recoverable state, per:
					// https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
			}

			if (errorDetail) {
					var msg = 'Sorry, your transaction could not be processed.';
					if (errorDetail.description) msg += '\n\n' + errorDetail.description;
					if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
					return alert(msg); // Show a failure message (try to avoid alerts in production environments)
			}

			// Successful capture! For demo purposes:
			console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
			var transaction = orderData.purchase_units[0].payments.captures[0];
			alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');

			// Replace the above to show a success message within this page, e.g.
			// const element = document.getElementById('paypal-button-container');
			// element.innerHTML = '';
			// element.innerHTML = '<h3>Thank you for your payment!</h3>';
			// Or go to another URL:  actions.redirect('thank_you.html');
		}).catch(e => {
			console.log('e', e)
			setOnErrorMessage(e.message);
		})
	)

	/* function createOrder (data,actions) {
	  return actions.order
	    .create({
	      purchase_units: [{
					amount: {
						value: amount,
					},
				}],
	    })
	    .then((orderID) => {
	      setOrderID(orderID);
	      return orderID;
	    });
	} */

	/* function onApprove (data, actions) {
	  return actions.order.capture().then(function (details) {
	    setOnApproveMessage(
	      `Transaction completed by ${details.payer.name.given_name}!`
	    );
	  });
	} */

	function onError (err) {
	  setOnErrorMessage(err.toString());
	}

	function onChange (event) {
	  setAmount(event.target.value);
	  setOrderID("");
	  setOnApproveMessage("");
	  setOnErrorMessage("");
	}

	return (
		<div className="paypal">
			<PayPalScriptProvider options={initialOptions}>
				<PayPalButtons
					style={{ layout: "horizontal", height: 55, color: 'white' }}
					fundingSource={FUNDING.PAYPAL}
					createOrder={createOrder}
					onApprove={onApprove}
					onError={onError}
					// forceReRender={[amount]}
				/>
			</PayPalScriptProvider>
			{onErrorMessage && (
				<div className="payment-error" role="alert">
					{onErrorMessage}
				</div>
			)}
		</div>
	)
}

export default Paypal
