import React from 'react'
import { Link } from 'react-router-dom'
import TrekListTile from './TrekListTile'
import "./TrekCategory.scss"

const TrekCategory = ({treks, category, link, full}) => {
	return (
		<div className='trek-list'>
			{ link
				? <Link to={link} >
						<h3>
							{category}
							<i className='icon ct-c'>&#xf104;</i>
						</h3>
					</Link>
				: <h3>{category}</h3>
			}
			<div className={`trek-grid ${full ? 'full' : ''}`}>
				{ treks?.map((trek, index) =>
					<TrekListTile trek={trek} key={`trek-tile-${index}`} />
				)}
			</div>
		</div>
	)
}

export default TrekCategory
