import React from 'react'
import "./NoMatch.scss"
import { Header, Footer } from './layout'
import { Link, useHistory } from 'react-router-dom'

export default function NoMatch(props) {
	const randomPos = Math.random().toFixed(2) * 100
	const history = useHistory()
	const showLayout = props.showLayout ?? true

	return (
		<div className='no-match'>
			{showLayout && <Header/> }
			<div className='content'  style={{backgroundPosition:`${randomPos}% center`, backgroundImage:`url(${process.env.PUBLIC_URL + '/images/bg/missing.jpg'})`}}>
				<div className='wrapper'>
					<h1>{props?.title ?? `Oops!`}</h1>
					<h5>{props?.subTitle ?? `This page is missing, let us know when you find it.`}</h5>
					<button onClick={() => history.goBack()}>Go Back</button>
					<h5>You are currently logged out.</h5>
					<Link to='/login'><button>Please sign in again</button></Link>
				</div>
			</div>
			{showLayout && <Footer/>}
		</div>
	)
}
