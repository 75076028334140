import _ from 'lodash'

export default class LocalizedText {
  static selectedLang = 'en'
  constructor(texts) {
    this.data = _.keyBy(texts, 'language')
  }

  get text() {
    if (this?.data[LocalizedText?.selectedLang])
      return this?.data[LocalizedText?.selectedLang]?.text
    else if (Object.keys(this?.data).length)// The selected language cannot be found. Try another.
      return this?.data[Object.keys(this.data)?.[0]]?.text;
    else
      return ""
  }
}
