import React, { memo } from 'react'

const Wrapper = memo((props) => {
	const { wrapped, children, ...other } = props
	if (wrapped)
		return <div {...other} >{children}</div>
	else
		return <>
			{props.children}
		</>
})

export default Wrapper
