import React, { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useField, splitFormProps } from 'react-form'
import {IMaskInput} from 'react-imask'
import Wrapper from 'components/Wrapper'

export const TextInput = memo(props => <input type="text" {...props} />);

TextInput.defaultProps = {
  value: ''
};

TextInput.propTypes = {
  value: PropTypes.string
};

export default memo(forwardRef((props, ref) => {
	const { label, wrapped, wrapperStyle, ...others } = props
  // Let's use splitFormProps to get form-specific props
  const [field, fieldOptions, rest] = splitFormProps(others);

  // Use the useField hook with a field and field options
  // to access field state
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
	} = useField(field, fieldOptions);

	const Comp = props.mask ? IMaskInput : TextInput


  // Build the field
  return (
    <Wrapper wrapped={wrapped} style={wrapperStyle}>
			{label && <label>{label}</label>}
      <Comp {...getInputProps({ ref, ...rest })} />{" "}
      {isValidating ? (
        <em>Validating...</em>
      ) : isTouched && error ? (
        <em>{error}</em>
      ) : null}
    </Wrapper>
  );
}))
