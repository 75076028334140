import React from 'react'
import "./Account.scss"
import { AA, AS } from 'reducers/app'
import { useSelector } from 'react-redux'
import { useQuery, useQueryClient } from "react-query"

const Account = () => {
	// const user = useSelector(AS.user)
	const qc = useQueryClient()
	const settings = qc.getQueryData('settings')
	const user = qc.getQueryData('me')
	const [lang] = settings?.languages.filter(l => l.id === user.language) ?? []
	return (
		<div className='account-page'>
			<img src={user.gravatar} alt="profile" />
			<p>Author: { user.author }</p>
			<p>Name: { user.firstName } { user.lastName }</p>
			<p>E-mail: { user.contactEmail }</p>
			<p>Primary Language: { lang?.iso?.toUpperCase() }</p>
			<p>{ Object.keys(user).map(k => `${k + "\n"}`).join() }</p>
			<p>{ JSON.stringify(user.treks_purchased) }</p>
			{/* <p>{ JSON.stringify(settings) }</p> */}
		</div>
	)
}

export default Account
