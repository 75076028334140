import React, { memo } from 'react'
import "./Preview.scss"
import LocalizedText from 'data/LocalizedText'
import { toNumericalDecimal } from 'utils/string'
import { setState } from 'reducers/app'

const Preview = memo((props) => {
	const trek = props.trek
	console.log('trek', trek)
	return (
		<div className='modal-content preview'>
			<div className='preview-image' style={{backgroundImage:`url("${trek?.promotional_photos?.[0]?.uri_image_med}")`}} />
			<div className='preview-content'>
				<h1 className='preview-content_title'>{ new LocalizedText(trek?.title).text }</h1>
				<div className='preview-content_details'>
					<div className='preview-content_details--author'>
						{trek?.author?.photo
							? <img src={trek?.author?.photo} alt='author'/>
							: <img src={process.env.PUBLIC_URL + "/images/default_avatar.svg"} alt='author'/>
						}
						<div>
							<h5>{trek?.author?.author}</h5>
							<h6>Version {trek?.version}</h6>
						</div>
					</div>
					{trek?.price === 0 ? <h2 className='price'>Free</h2> : (<h2 className='price'><span>$</span>{toNumericalDecimal(trek?.price)}</h2>) }
				</div>
				<div className='bar'/>
				<div className='preview-content_purchase'>
					<span><i className='icon ct-s'>&#xf11c;</i> Secure Transaction</span>
					<button onClick={props?.onNext}>Buy Now</button>
				</div>
			</div>
		</div>
	)
})

export default Preview
