import React, { useReducer, useEffect } from 'react'
import { useSelector } from 'react-redux'
import NoMatch from 'pages/NoMatch'
import Browse from 'pages/app/browse/Browse'
import Search from 'pages/app/browse/Search'
import Saved from 'pages/app/browse/Saved'
import Owned from 'pages/app/browse/Owned'
import Info from 'pages/app/info/Info'
import Account from 'pages/app/account/Account'
import TrekDetail from 'pages/app/browse/TrekDetail'
import Test from 'pages/Test'
import AppHeader from 'pages/app/layout/AppHeader'
import AppFooter from 'pages/app/layout/AppFooter'
import { PurchaseContext, purchaseState, showPurchase, hidePurchase } from 'contexts/purchaseContext'
import { UserContext } from 'contexts/userContext'
import Purchase from './modal/Purchase'
import { useQuery } from "react-query"
import { axios } from 'utils/axios'
import { AA, AS } from 'reducers/app'
// import tokens from 'utils/token'
import {
  // BrowserRouter as Router,
  Switch,
	Route,
	Redirect,
	// Link,
	// useParams,
	// useRouteMatch,
	useLocation,
	useHistory,
} from "react-router-dom";


const reducer = (state, newState) => ({ ...state, ...newState })
const AppHome = () => {
	const userData = useSelector(AS.user)
	const [purchaseState, setPurchaseState] = useReducer(reducer, {show:false, content:undefined, title:'Purchase'})
	const [userState, setUserState] = useReducer(reducer, {user:userData})
	const showPurchase = (content, onConfirm, onCancel) => {
		console.log("purchaseState", Object.keys(purchaseState), content)
		setPurchaseState({show:true, stateOverride:undefined, content, onConfirm, onCancel})
	}
	const showInstructions = (content, onConfirm, onCancel) => {
		console.log("showInstructions", Object.keys(purchaseState), content)
		setPurchaseState({show:true, stateOverride:'play', content, onConfirm, onCancel})
	}
	const hidePurchase = () => setPurchaseState({show:false})
	// const token = tokens.useToken()
	const history = useHistory()

	const account = useQuery('me', () => axios.get(process.env.REACT_APP_API_URL + `/me`, {_auth:true}).then(async resp => {
		console.log('resp.data', resp.data)
		setUserState({user:resp.data})
		await AA.setUser(resp.data)
		return resp.data
	}), {
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000,
		initialData: userData
	})

	const settings = useQuery('settings', () => axios.get(process.env.REACT_APP_API_URL + `/settings`).then(resp => resp.data), {
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000,
	})

	// if (!!!token) {
	// 	history.push('/login')
	// }

	return (
		<div>
			<PurchaseContext.Provider value={{...purchaseState, showPurchase, showInstructions, hide:hidePurchase}}>
				<UserContext.Provider value={{...userState}}>
					<div style={{minHeight:'100vh', backgroundColor: 'white'}}>
						<AppHeader />
						<Switch>
							<Route exact path='/browse' component={Browse} />
							<Route exact path='/browse/saved' component={Saved} />
							<Route exact path='/browse/owned' component={Owned} />
							<Route exact path='/search' component={Search} />
							<Route exact path='/info' component={Info} />
							<Route exact path='/account' component={Account} />
							{/* <Route exact path='/account' component={AppHome} /> */}
							<Route exact path='/t/id/:uuid' component={TrekDetail} />
							<Route exact path='/t/id/:uuid/dl' component={Test} />
						</Switch>
						<Purchase/>
					</div>
				</UserContext.Provider>
			</PurchaseContext.Provider>
			<AppFooter />
		</div>
	)
}

export default AppHome
