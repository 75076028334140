import { select, takeEvery, put, call } from 'redux-saga/effects'
import { axios, logout as axiosLogout } from 'utils/axios'
import { AA } from 'reducers/app'
import { toast } from 'react-toastify'

const getMe = function * ({tokens}) {
	try {
		console.log('Start get me saga')
		const { data, status } = yield axios.get(process.env.REACT_APP_API_URL + '/me', {
			_auth: true,
			validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
		});
		if (status === 200) {
			yield put(AA.setState({user:data, setup:true, verified:true}))
		} else if (status === 403) {
			if (data?.error && data?.location === '/auth/setup') {
				// The user needs to be setup.
				yield put(AA.setState({verified:false, setup:false}))
			} else if (data?.error && data?.location === '/user/verify') {
				yield put(AA.setSetup(true))
			}
		}
	} catch(e) {
		console.log("Error", e)
	}
}

const redirect = function ({path}) {
	try {
		window.location.href = path
	} catch(e) {
		console.log('e', e)
	}
}

const verify = function * ({key}) {
	if (!key) return
	const { data, status } = yield axios.get(process.env.REACT_APP_API_URL + `/user/verifyEmail?key=${key}`, {
		_auth: true,
	  validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
	});
	console.log("email verification data", data, status)
	if ((status === 200 || status === 302) && data?.success) {
		toast.success(data?.success)
		yield put(AA.setVerified(true))
	} else {
		if (data?.error)
			toast.error(data?.error)
		else {
			toast.error("There was an error trying to verify your e-mail.")
		}
	}
}

const logout = function * () {
	yield put(AA.reset())
	// yield tokenQuery.logout()
	axiosLogout()
	yield call(redirect, {path:'/logout'})
}

const rootSaga = function* root () {
  yield takeEvery('SAGA_ME', getMe)
  yield takeEvery('SAGA_REDIRECT', redirect)
  yield takeEvery('SAGA_VERIFY', verify)
  yield takeEvery('SAGA_LOGOUT', logout)
}

export default rootSaga
