import { default as createTokenQuery, setQueryClient as sQC } from "libs/token-query"
import Axios from 'axios'
import { ToastContainer, toast, Slide } from 'react-toastify'
import ObjectError from 'utils/ObjectError'

/* export interface Config<TToken, TLoginParams> {
  tokenExpired: (token: TToken) => boolean;
  refreshExpired: (token: TToken) => boolean;
  sendLogin: (loginParams: TLoginParams) => Promise<TToken>;
  sendRefresh: (token: TToken) => Promise<TToken>;
  retry: (failCount: number, error: any) => boolean;
  refreshExpiredError: any;
  queryKey?: string;
  shouldRefreshOnBackground?: (token: TToken) => boolean;
} */

// interface TToken {
//     authToken: string;
//     refreshToken: string;
//     authTokenExpiry: number;
//     refreshTokenExpiry: number;
// }

let attempts = 0
let toAttempt
const sendLogin = async function(values, params) {
	// console.log('values', values, params, arguments)
	/*
	meta = {
      deviceName: Constants.deviceName,
      platform: Constants.platform,
      version: Constants.expoVersion,
      deviceYearClass: Constants.deviceYearClass
		}
		*/
	return new Promise(async (resolve, reject) => {
		try {
			attempts++
			if (attempts < 6) {
				const vars = new URLSearchParams({
					next:false,
					id: 'web',
					meta:{
						deviceName: 'Web',
						platform: navigator.userAgent,
						version: 1,
						deviceYearClass: 'N/A'
					},
					...(values.remember) ? {permanent:true} : {}
				})
				// await new Promise(resolve => setTimeout(resolve, 100000));
				const { identifier, password } = values
				let res = await Axios.post(process.env.REACT_APP_API_URL + `/auth/local?${vars.toString()}`, { identifier, password }, {
					// cancelToken,
					validateStatus: function (status) { return status < 500; }, // Resolve only if the status code is less than 500 */
				})
				// console.log('res', res)
				if (res.status === 200)
				{
					resolve({
						accessToken: res.data.tokens.accessToken,
						accessTokenExpiry: res.data.tokens.accessTokenExpiry,
						userId: res.data.userUuid,
						...(values.remember) ? {
							refreshToken: res.data.tokens.refreshToken,
							refreshTokenExpiry: res.data.tokens.refreshTokenExpiry
						} : {}
					})
					// dispatch(AA.setState({tokens:res.data?.tokens, user:res.data?.userUuid, loggedIn:true}))
					// dispatch({type:"SAGA_ME"})
					// return [null, res.data]
				} else if (res.status === 429) {
					reject(new ObjectError(undefined, "tooMany", "Too many attempts. Please try again in a minute."))
				} else {
					reject(new ObjectError(undefined, "invalidLogin", "Unable to login. \n\r\nPlease check your username and/or password" ))
				}
			} else {
				if (!toAttempt) {
					toAttempt = setTimeout(() => {
						attempts = 0
						toAttempt = undefined
					}, 30000)
				}
				reject(new ObjectError(undefined, "tooMany", "Too many attempts. Please try again in 30 seconds."))
			}
		} catch(e) {
			console.log('e', e)
			if (e?.code)
				reject(e)
			reject(new ObjectError(undefined, "invalidLogin", "Unable to login. \n\r\nPlease check your username and/or password" ))
		}
	})
}

const sendRefresh = async function(token) {
	return new Promise(async (resolve, reject) => {
		// const tokens = yield call(api.post, app.url + '/auth/token', {refreshToken, user, identifier: Constants.installationId})
			// console.log('tokens', token)
		let config = token?.accessToken ?
				{ headers: { Authorization: `Bearer ${token?.accessToken}` } } :
				{}
		let userId = token.userId
		let res = await Axios.post(process.env.REACT_APP_API_URL + `/auth/token`, {
			refreshToken: token.refreshToken,
			user: userId,
			identifier: 'web'
		}, {
			...config,
			// cancelToken,
			validateStatus: function (status) { return status < 500; }, // Resolve only if the status code is less than 500 */
		})
		// console.log('res', res)
		if (res.status === 200)
		{
			resolve({
				accessToken: res.data.accessToken,
				accessTokenExpiry: res.data.accessTokenExpiry,
				userId,
				refreshToken: res.data.refreshToken,
				refreshTokenExpiry: res.data.refreshTokenExpiry
			})
		} else {
			reject(new ObjectError(undefined, "invalidRefresh", "Unable to refresh token."))
		}
	})
}

const tokenExpired = (token) => {
	// console.log("check if token expired", token?.accessTokenExpiry === undefined || Date.now() > token.accessTokenExpiry, Date.now(), token?.accessTokenExpiry)
	return token?.accessTokenExpiry === undefined || Date.now() > token.accessTokenExpiry
}

const refreshExpired = (token) => {
	// console.log("check if refresh is expired", token?.refreshTokenExpiry === undefined || Date.now() > token.refreshTokenExpiry)
	// return token?.refreshTokenExpiry !== undefined && Date.now() < token.refreshTokenExpiry
	return token?.refreshTokenExpiry === undefined || Date.now() > token.refreshTokenExpiry
	//
}

const tokenQuery = createTokenQuery({
  queryKey: 'token',
  tokenExpired,
  refreshExpired,
  sendLogin,
	sendRefresh,
	loggedIn: (token) => {
		// console.log('check if logged in:', token.userId)
		return (token.accessToken) ? token.userId : undefined
	},
  retry: (count, error) => count < 3 && !error.statusCode === 401,
  refreshExpiredError: new Error('401-Refresh token expired'),
	onLogout: () => {
		toast.warn("You have been signed out.", {
			toastId: "signedOut",
			autoClose: 10000,
			pauseOnFocusLoss:true,
		})
  }
  // shouldRefreshOnBackground
});

export function setQueryClient(client) {
	sQC(client)
	// init(1000 * 60 * 40)
	init()
}

// export default tokenQuery
const { init, useToken, callLogin, useLogin, logout, refresh, getToken, loggedIn } = tokenQuery;

const Token = { init, useToken, login:callLogin, useLogin, logout, refresh, getToken, loggedIn, refreshExpired, tokenExpired }
export default Token
