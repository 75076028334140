import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Link, useLocation, useHistory } from 'react-router-dom'
// import axios from "axios"
import { axios, authRequired } from 'utils/axios'
import "./Browse.scss"
import { useQuery, useQueryClient } from "react-query"
import NoMatch from 'pages/NoMatch'
import LocalizedText from 'data/LocalizedText'
import { selectors as AS } from 'reducers/app'
import { useSelector } from 'react-redux'
import tokenQuery from 'utils/token'
import ReviewIcon from 'components/ReviewIcon'
import StarReviewIcons from 'components/StarReviewIcons'
import TrekCategory from './components/TrekCategory'
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Pagination, A11y, Autoplay } from 'swiper';
import HighlightedTrek from './components/HighlightedTrek'

SwiperCore.use([Pagination, A11y, Autoplay])

function Browse() {
	// const [homepage, setHomepage] = useState({})
	const [vars, setVars] = useState("")
	// const tokens = tokenQuery.useToken()
	// console.log("browse token expired", tokens, tokenQuery.tokenExpired(tokens))
	// console.log('Browse tokens', tokens)

	// const tokens = useSelector(AS.tokens)
	const { data:location } = useQuery('location', async () => {
		console.log("calling location queryFn.")
		return new Promise(async (res, rej) => {
			try {
				const location = await new Promise((resolve, reject) => {
					console.log('navigator.geolocation', navigator.geolocation)
					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(resolve, reject);
					} else {
						// x.innerHTML = "Geolocation is not supported by this browser.";
					}
				})
				const vars = new URLSearchParams()
				vars.set('lat', location.coords.latitude)
				vars.set('lng', location.coords.longitude)
				// console.log("vars", vars.toString())
				setVars(vars)
				res(location)
			} catch(error) {
				console.log("error:", error)
				rej(error)
				/* switch(error.code) {
					case error.PERMISSION_DENIED:
						x.innerHTML = "User denied the request for Geolocation."
					break;
					case error.POSITION_UNAVAILABLE:
						x.innerHTML = "Location information is unavailable."
					break;
					case error.TIMEOUT:
						x.innerHTML = "The request to get user location timed out."
					break;
					case error.UNKNOWN_ERROR:
						x.innerHTML = "An unknown error occurred."
					break;
					default:
					break;
				} */
			}
		})
	}, {
		cacheTime: 1000,
		// cacheTime: 5 * 60 * 1000,
		// refetch: false,
	})
	// console.log("location", location, vars.toString())
	const history = useHistory()
	// console.log('axios', axios)

	const { isSuccess, status, data, error, isFetching } = useQuery(['browse', vars.toString()], async () => {
		// console.log('browse tokens?.accessToken', tokens?.accessToken, tokens)
		// console.log("________ token", tokenQuery.getToken())
		// const tokens = await tokenQuery.getToken()
		// console.log("browse queryFn called.", tokens.accessToken)
		// return axios.get(process.env.REACT_APP_API_URL + `/index2?${vars ? vars.toString() : ""}`, {
			// console.log("location", location)
		return axios.get(process.env.REACT_APP_API_URL + `/index2?${vars ? vars.toString() : ""}`, {
			_auth: true,
			// headers: {
			// 	cookie:'',
			// },
			// ...tokens?.accessToken ? { headers: { Authorization: `Bearer ${tokens?.accessToken}` } } : {},
		  // withCredentials: true,
		  // validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
		});
		// console.log('status', status)
		// if (data && status === 200) {
		// 	setHomepage(data)
		// } else if (status === 401) {
		// 	console.log("status", status, data)
		// 	// tokenQuery.logout()
		// 	// history.push('/login')
		// }
		// return data
	}, {
		// enabled: false,
		// enabled: !tokenQuery.tokenExpired(tokens),
		// retry: false,
		cacheTime: 8 * 60 * 1000
		// retry: authRequired
		// refetch: false,
		// retry: false,
	})
	// console.log("browse", status, error, data, isSuccess)
	const [type, setType] = useState('list')

	if (!data || error)
		return null

	const {data:homepage} = data

	return (
		<div className='browse'>
			{ Object.keys(homepage).map((key, idx) => {
				if (key === 'featured' && homepage[key].data.length > 0) {
					return <div className='highlighted-slides' key={'featured'}>
						<Swiper
							{...{
							// dots: true,
							// infinite: true,
							// speed: 500,
							// slidesToShow: 1,
							autoplay: {
								delay: 5000,
							},
							slidesPerView: 'auto',
							centeredSlides:true,
							keyboard: { enabled: true },
							loop: true,
							// loop: homepage[key].data > 1,
							spaceBetween: 10,
							...homepage[key].data.length > 1 ? {
								grabCursor: true,
								scrollbar:{ draggable: true },
								pagination: {
									el: '.swiper-pagination',
									clickable: true,
								}
							} : {},
						}} >
							{[...homepage[key].data, ...homepage[key].data, ...homepage[key].data].map((highlightedTrek, idx) =>
								<SwiperSlide key={`highlighted-${idx}`} >
									<HighlightedTrek trek={highlightedTrek} />
								</SwiperSlide>
							)}
						</Swiper>
    				<div className="swiper-pagination"/>
					</div>
				} else {
					//console.log("key", key, homepage[key].data)
					return homepage[key].data.length > 0
						? <TrekCategory key={`cat-${idx}`} treks={homepage[key].data} category={new LocalizedText(homepage[key].title).text} />
						: null
					}
				}
			)}
			{/* <TrekCategory treks={treks} category={"Near You"} link="/search?nearby" /> */}
			{/* <TrekCategory treks={_.shuffle(treks)} category={"Trending"} link='/search?popular' /> */}
		</div>
	)
}

export default Browse
