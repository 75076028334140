import React, { useEffect, useState, useCallback } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// import "./CheckoutForm.scss"
import "./AddCard.scss"
import { axios } from 'utils/axios'
import CardSection from './CardSection';
import Spinner from 'components/Spinner'

const AddCard = function (props) {
  const stripe = useStripe();
  const elements = useElements();
	const [clientSecret, setClientSecret] = useState('');
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState();
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);

	const { onSucceeded } = props

	/* useEffect(() => {
    // Create PaymentIntent as soon as the page loads
		axios.post(process.env.REACT_APP_API_URL + "/account/create-setup-intent", {
			// trek: props.trek.uuid
		}, {
			_auth: true,
			headers: {
				"Content-Type": "application/json"
			}
		}).then(res => {
			console.log('data', res)
			setClientSecret(res.data.clientSecret);
		})

    // window
    //   .fetch("/account/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({trek: [{ id: "xl-tshirt" }]})
    //   })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //     setClientSecret(data.clientSecret);
    //   });
  }, [props.trek]); */

	const cancelSetupIntent = (clientSecret) => (
		axios.post(process.env.REACT_APP_API_URL + "/account/cancel-setup-intent", {
			client_secret: clientSecret
		}, {
			_auth: true
		}).then(res => {
			console.log('cleanup response', res)
			// setClientSecret(res.data.clientSecret);
		})
	)

	useEffect(() => {
    // Create SetupIntent as soon as the page loads
		if (!clientSecret) {
			axios.post(process.env.REACT_APP_API_URL + "/account/create-setup-intent", {}, {
				_auth: true
			}).then(res => {
				console.log('data', res)
				setClientSecret(res.data.clientSecret);
			})
		}

    // window
    //   .fetch("/account/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({trek: [{ id: "xl-tshirt" }]})
    //   })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(data => {
    //     setClientSecret(data.clientSecret);
    //   });

		return () => {
			if (clientSecret) {
				cancelSetupIntent(clientSecret)
			}
		}
		// eslint-disable-next-line
  }, [clientSecret]);

  const handleSubmit = useCallback(async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup(`${clientSecret}`, {
      payment_method: {
        card: elements.getElement(CardElement),
        // billing_details: {
          // name: 'Jenny Rosen',
        // },
      }
    });

    if (result.error) {
      // Display result.error.message in your UI.
			setError(`Adding card failed ${result.error.message}`);
      setProcessing(false);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
			console.log('payload', result)
      setError(null);
      setProcessing(false);
      setSucceeded(true);
			onSucceeded?.()
    }
  }, [elements, stripe, clientSecret, onSucceeded])

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
		console.log('event', event)
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  }

	useEffect(() => {
		console.log('disabled:', disabled, processing, error, succeeded)
	}, [disabled, error, succeeded, processing])

	const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'brandon-grotesque',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

	const { onRemoveCard } = props

	const onRemoveCardHandler = useCallback(async () => {
		if (clientSecret) {
			await cancelSetupIntent(clientSecret).catch(e => {
				console.log("doesn't matter.", e)
			})
		}
		onRemoveCard()
	}, [onRemoveCard, clientSecret])

  return (
		<div className="viewCard addCard">
			<form onSubmit={handleSubmit}>
				<CardSection options={cardStyle} onChange={handleChange} />
				<button className="addButton" disabled={processing || disabled || succeeded}>
					<span id="button-text">
						{processing ? (
							<Spinner />
						) : (
							"Add Card"
						)}
					</span>
				</button>
				{/* Show any error that happens when processing the payment */}
				{error && (
					<div className="card-error" role="alert">
						{error}
					</div>
				)}
			</form>
			<div className='remove-card' onClick={onRemoveCardHandler}>
				<i className='icon ct-s'>&#xf135;</i>
			</div>
		</div>
  );
}

export default AddCard
