import React from 'react'
import { Parallax } from "react-parallax";
import { ReactComponent as Logo } from "../assets/CTLogo_text.svg"
import './Splash.scss'
import Footer from './layout/Footer';
import Header from './layout/Header';
import { globalStyle as GS, globalFunc as GF, Colors } from '../global/styles'

export default function Splash() {
	return (
		<div className='splash'>
			<Header/>
			<Parallax
				// bgImageStyle={{width:'initial', height:'initial', minWidth:'100vw', minHeight:'100vh'}}
				style={{height:'100vh'}}
				bgImage={process.env.PUBLIC_URL + '/images/photo1.jpg'}
				strength={200}>
				<div style={{ height: '100vh' }}>
					<div style={{ height:'100vh', width:'100%', display:'flex', flexDirection:'column',  }}>
						<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
							<Logo style={{...GS.shadow, width:150, marginBottom:'10%'}} />
							<h1 style={{...GS.shadow, marginBottom:'10%'}}>LIVE. LEARN. GIVE.</h1>
						</div>
						<div>
							<div className="band-20 band--blue-green-90" />
							<div className="band--blue-90 content-copy">
								<p>ChariTrek<sup>®</sup> exists to connect people through experiences. We use technology to provide a dynamic digital environment to share information, adventures and interests. We make it easy to create and enjoy personal journeys that connect humanity and enhance lives.</p>
							</div>
						</div>
					</div>
				</div>
			</Parallax>
			<div className="band-20 band--blue-green-90" />
			<Parallax
				style={{height:'600px'}}
				// bgImgStyle={{height:'600px'}}
				bgImage={process.env.PUBLIC_URL + '/images/photo2.jpg'} strength={300}>
				<div style={{ height:'600px', width:'100%', display:'flex', flexDirection:'column',  }}>
					<div>
						<div className="band--blue-90 explain content-copy">
							<h2>$99 SPOKANE TOUR</h2>
							<h3>FOR A LIMITED TIME</h3>
							<p className='cta'>PLUS a free founder membership. Create an account, which will allow you special access to all of the latest news and you will get a demonstration of the amazing technology that drives ChariTrek<sup>®</sup> and it’s ability to bring your journey to life.</p>
						</div>
					</div>
					<div style={{ flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center' }}>
						<div className='band--blue-30 tour'>
							<h2 style={GS.shadow}>& FREE<br/>FOUNDER MEMBERSHIP</h2>
							<h3 style={GS.shadow}>WITH YOUR TOUR PURCHASE</h3>
						</div>
					</div>
				</div>
			</Parallax>
			<Footer />
		</div>
	)
}
