import React, { Fragment, useCallback } from 'react'
import { ReactComponent as Logo } from "assets/CTLogo.svg"
import "./AppHeader.scss"
import { Link, useLocation } from 'react-router-dom'
import Search from './Search'
import { useSelector, useDispatch } from 'react-redux'

const AppHeader = () => {
	const {pathname} = useLocation()

	console.log("pathname", pathname)

	const bLinks = {
		saved: { icon: 0xf106, title: 'Saved' },
		owned: { icon: 0xf150, title: 'Owned' },
	}

	const infoLinks = {
		// company: { icon: 0xf106, title: 'Company' },
		// charities: { icon: 0xf106, title: 'Charities' },
		// faq: { icon: 0xf106, title: 'FAQ' },
		// legal: { icon: 0xf106, title: 'Legal' },
		// support: { icon: 0xf106, title: 'Support' }
	}
	const accountLinks = {
		overview: { icon: 0xf106, title: 'Overview', link:'/account' },
		// charities: { icon: 0xf106, title: 'Charities' },
		// faq: { icon: 0xf106, title: 'FAQ' },
		// legal: { icon: 0xf106, title: 'Legal' },
		// support: { icon: 0xf106, title: 'Support' }
	}

	const dispatch = useDispatch()
	const onLogout = useCallback(() => {
		dispatch({type: "SAGA_LOGOUT"})
	}, [dispatch])

	return (
		<div className='app-header'>
			<div className='app-header-bar'>
				<Link to="/">
					<Logo className="app-header-logo" />
				</Link>
				<div className='links'>
					<Link to="/browse">
						<i className='icon ct-r'>&#xf107;</i>
						<span>Browse</span>
					</Link>
					{/* <Link to="/create">
						<i className='icon ct-r'>&#xf124;</i>
						<span>Create</span>
					</Link> */}
					<Link to="/info">
						<i className='icon ct-r'>&#xf13a;</i>
						<span>Info</span>
					</Link>
				</div>
				<Search />
				<div className='account'>
					<Link to="/account">
						<span>Account</span>
						<i className='icon ct-r'>&#xf128;</i>
					</Link>
				</div>
			</div>
			<div className='app-header-bar_sub'>
				{
					(pathname.substr(0,7) === "/browse" || pathname.substr(0,5) === "/t/id")
					? <div className='container'>
							{
								Object.keys(bLinks).map((key, i) => {
									const active = pathname === `/browse/${key}`
									return <Link key={`browse-${i}`} to={`/browse/${key}`} className={`${active ? 'active' : ''}`}>
										<i className={`icon ${active ? 'ct-s' : 'ct-r'}`}>{String.fromCharCode(bLinks[key].icon)}</i>
										<span>{bLinks[key].title}</span>
									</Link>})
							}
						</div>
					: (pathname.substr(0,5) === "/info")
					? <div className='container'>
						{
							Object.keys(infoLinks).map((key, i) => {
								const active = pathname === `/info/${key}` || (pathname === '/info' && i === 0)
								return <Link key={`info-${i}`} to={`/info/${key}`} className={`${active ? 'active' : ''}`}>
									<i className={`icon ${active ? 'ct-s' : 'ct-r'}`}>{String.fromCharCode(infoLinks[key].icon)}</i>
									<span>{infoLinks[key].title}</span>
								</Link>
							})
						}
						</div>
					: (pathname.substr(0,8) === "/account")
					? <div className='container'>
							{
								Object.keys(accountLinks).map((key, i) => {
									const active = pathname === `/account/${key}` || (pathname === '/account' && i === 0)
									return <Link key={`account-${i}`} to={accountLinks[key].link} className={`${active ? 'active' : ''}`}>
										<i className={`icon ${active ? 'ct-s' : 'ct-r'}`}>{String.fromCharCode(accountLinks[key].icon)}</i>
										<span>{accountLinks[key].title}</span>
									</Link>
								})
							}
							<div style={{flexGrow:1}}/>
							<Link key="account-logout" to={'#'} onClick={onLogout}><i className={`icon ct-r`} style={{justifySelf:'flex-end'}}>&#xf107;</i><span>Sign Out</span></Link>
						</div>
					: null
				}
			</div>
		</div>
	)
}

export default AppHeader
