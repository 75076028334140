import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import HiddenField from './components/HiddenField'
import Checkbox from './components/Checkbox'
import AccountSelect from './components/AccountSelect'
import Valid from './helpers/validations'
import Axios from 'axios'
import Helmet from 'react-helmet'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

const Create = {
	auth: 'guest',
	bg:BG.KAYAK,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Create a ChariTrek account and discover a new way to look at the world.</h1>
				</div>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}, props) => {
		return <div className='form-area'>
			<Helmet>
				<title>Create Account - ChariTrek</title>
			</Helmet>
			<AccountSelect />
		</div>
	}
}

export default Create
