import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Checkbox from './components/Checkbox'
import Valid from './helpers/validations'
import Helmet from 'react-helmet'
import axios from 'axios'
// import { queryCache } from 'react-query'
import { parseJSON } from '../../utils/json'
import { store } from 'store'
import { AA } from 'reducers/app'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

function isChecked (values) {
	console.log('values', values)
	if (!values)
		return "Required"
	return false
}

const Setup = {
	auth: true,
	onSubmit: async (values, instance, cancelToken, dispatch) => {
		const tokens = store.getState().app.tokens
		let config = tokens ?{ headers: { Authorization: `Bearer ${tokens.accessToken}` } } : {}
		let { data, status } = await axios.post(process.env.REACT_APP_API_URL + `/auth/setup`, values, {
			...config,
			cancelToken,
			validateStatus: function (status) { return status < 500; /* Resolve only if the status code is less than 500 */ }
		})
		console.log('data', data)
		if (status === 200)
		{
			dispatch(AA.setState({setup:true}))
			return [null, data]

			// if (!!data?.user) {
			// } else if (!!data?.user) {
			// 	return [{ id: 'errorReg', message: "There was an error registering."}]
			// }
		} else {
			if (status === 401)
			{
				console.log('data?.success !== undefined && data?.error', data?.success !== undefined, data?.error)
				if (data?.success !== undefined && data?.error)
					return [{id:"errorCreate", message:data?.error}]
				else
					return [{id:"errorCreate", message:"There was an error creating your account."}]
			}
		}
	},
	bg:BG.POLAR_BEAR,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Almost done!<br/>Just one more step.</h1>
				</div>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Setup Account - ChariTrek</title>
			</Helmet>
			{/* <h1>Reset your password</h1> */}
			<Form>
				<InputField field="name" placeholder="Name" required />
				<InputField field="email" placeholder="Contact E-mail" type="email" required />
				<InputField field="confirm-email" placeholder="Confirm Contact E-mail" type="email" required />
				<InputField mask='00/00/0000' field="dob" placeholder="MM/DD/YYYY" {...Valid.date} required />
				<InputField mask='00000[-0000]' field="zipcode" placeholder="Postal Code" type="text" {...Valid.postal} required />
				<Checkbox field='agree-to-terms' validate={(v) => !v ? "Required" : false} >
					<p style={{textAlign:'left', marginTop:'16px', userSelect:'none'}}>Agree to <Link to='/legal/terms' target="_blank">Terms & Conditions</Link> and read <Link to='/legal/privacy' target="_blank">Privacy Policy</Link></p>
				</Checkbox>
				<div>
					<button type="submit" disabled={isSubmitting || !canSubmit}>
						{isSubmitting ? "Creating account..." : "CREATE ACCOUNT"}
					</button>
				</div>
			</Form>
		</div>
	)
}
export default Setup
