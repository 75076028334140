import React from 'react'
import './Legal.scss'
import { Helmet } from "react-helmet";

export default function Refund() {
	return (
		<div>
			<>
				<Helmet>
					<title>Refund Policy - ChariTrek</title>
				</Helmet>
				<div className="title">
						<h1>Returns and Refunds Policy</h1>
				</div>
				<div className="title--sub">
						<p>Last updated: July 8, 2020</p>
				</div>
				<p>Thank you for shopping at ChariTrek. If you are not entirely satisfied with your purchase, we're here to help. </p>
				<p>Our purchased services can be refunded within 30 days of the original purchase if the service has not been used.</p>
				<p>To be eligible for a return, please make sure that:</p>
				<ul>
						<li>The service was purchased in the last 30 days</li>
						<li>You haven't been scheduled yet for the tour.</li>
						<li>You haven't used the tour.</li>
						<li>You no longer wish to use the service.</li>
				</ul>
				<p>Please contact us:</p>
				<ul>
						<li>By email: <a href="mailto:support@charitrek.com">support@charitrek.com</a></li>
				</ul>
				<p>Send your account e-mail or any other identifiable information <strong>(no passwords)</strong> with any proof of purchase, to:</p>
				<p>ChariTrek / P.O. Box 136 / Deer Park, WA 99006</p>
				<p className="s"> <strong>Sale items</strong></p>
				<p>Sale items can be refunded.</p>
				<p className="s"> <strong>Contact us</strong></p>
				<p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
				<ul>
						<li>By email: <a href="mailto:support@charitrek.com">support@charitrek.com</a></li>
				</ul>
			</>
		</div>
	)
}
