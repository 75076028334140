import React, { useCallback, useContext } from 'react'
import "./HighlightedTrek.scss"
import LocalizedText from 'data/LocalizedText'
import { toNumericalDecimal } from 'utils/string'
import { Link } from 'react-router-dom'
import { PurchaseContext, purchaseState, showPurchase, hidePurchase } from 'contexts/purchaseContext'

function HighlightedTrek({trek}) {
	const { showPurchase } = useContext(PurchaseContext)

	const purchaseEvent = useCallback(
		async () => {
			console.log("purchase event")
			// const url = `${window.location.origin}/t/${trek.uuid}`
			console.log('trek', trek)
			showPurchase(trek?.trek?.uuid)

		},
		[trek, showPurchase],
	)

	const price = trek.trek.price
	return (
		<div className='highlighted-trek'>
			<Link to={`/t/id/${trek.trek.uuid}`}>
				<div className='slide image' style={{ backgroundImage:`url("${trek.trek.promotional_photos[0].uri_image_full}")`}}/>
			</Link>
			<div className='label'>
				<div className='text'>
					<h3>{new LocalizedText(trek.title).text}</h3>
					<h5>{new LocalizedText(trek.summary).text}</h5>
				</div>
				<div className='cta'>
					<p className='version'>{`Version ${trek.trek.version}`}</p>
					<Link className='price-link' to={'#'} onClick={purchaseEvent}>
						<div className='price'>{price === 0 ? 'Free' : `$${toNumericalDecimal(price)}`}</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default HighlightedTrek
