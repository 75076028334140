import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Helmet from 'react-helmet'

const SignupThanks = {
	auth: false,
	bg:BG.STREET,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Thanks for signing up!</h1>
				</div>
				<p>You’ll receive an email when your account is ready to be activated.</p>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => {
		const url = encodeURIComponent(`${window.location.origin}/register`)
		return (
			<div className='form-area'>
				<Helmet>
					<title>Thanks - ChariTrek</title>
				</Helmet>
				<div style={{flexGrow:1}}/>
				<h1 style={{marginBottom:'20px'}}>Tell your friends</h1>
				<div className='social-icons'>
					<a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer">
						<i className='icon ct-s'>&#xf111;</i>
					</a>
					<a href={`http://www.twitter.com/intent/tweet?url=${url}`} target="_blank" rel="noopener noreferrer">
						<i className='icon ct-s'>&#xf136;</i>
					</a>
				</div>
				<div style={{flexGrow:1}}/>
				<Link to="/login">
					<h4 style={{marginTop:'40px'}}>Back to Login</h4>
				</Link>
			</div>
		)
	}
}
export default SignupThanks
