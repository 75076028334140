import React, { memo, useState, useEffect } from 'react'
import LocalizedText from 'data/LocalizedText'
import ReviewIcon from 'components/ReviewIcon'
import StarReviewIcons from 'components/StarReviewIcons'
import moment from 'moment'
import "./ReviewEntry.scss"

const ReviewEntry = memo((props) => {
	const {title, comment, fun, difficult, educational, rating, updatedAt, author, version} = props.review
	return (
		<div className='review-entry'>
			<div className='review-entry_top'>
				<div className='title'>
					<h4>{ new LocalizedText(title).text }</h4>
					<h5>
						<span>{`${author.author} - `}</span>
						<span className='date'>{`${moment(updatedAt).fromNow()} - `}<span className="tooltiptext">Tooltip text</span></span>
						<span>{`Version: ${version || "n/a"}`}</span>
					</h5>
				</div>
				<div className='ratings'>
					<div className='ratings-categories'>
						<ReviewIcon value={difficult} type="dif" />
						<ReviewIcon value={educational} type="edu" />
						<ReviewIcon value={fun} type="fun" />
					</div>
					<StarReviewIcons value={rating} />

				</div>
			</div>
			<p className='comment'>{ new LocalizedText(comment).text }</p>
		</div>
	)
})

export default ReviewEntry
