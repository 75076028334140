import React, { useState, memo, useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import "./Page.scss"

export const BG = {
	STREET:1,
	KAYAK:2,
	AIRPLANE:3,
	HIKERS:4,
	POLAR_BEAR:5,
}

const BG_STYLE_OVERRIDE = {
	2:{backgroundPosition:'right bottom', filter:'blur(3px)', transform:'scale(1.1)'},
	3:{filter:'blur(3px)', transform:'scale(1.1)'},
	4:{filter:'blur(3px)', transform:'scale(1.1)'},
	5:{filter:'blur(3px)', transform:'scale(1.1)'}
}
const COLOR_OVERRIDE = {
	1:{backgroundColor:'#C8D853'},
	4:{backgroundColor:'#C8D853'},
}

export default memo((props) => {
	const [bg, setBg] = useState(props?.bg ?? 1)
	const [photoArea, setPhotoArea] = useState(<div/>)
	const [formArea, setFormArea] = useState(<div/>)

	useEffect(() => {
		setBg(props.bg)
	}, [props.bg])
	useEffect(() => {
		setPhotoArea(props.photoArea)
	}, [props.photoArea])
	useEffect(() => {
		setFormArea(props.formArea)
	}, [props.formArea])
	/* let rightContent
	console.log("bg", bg)
	switch(bg) {
		case 2:
			rightContent =
		break;
		default:
			rightContent = <div>
							<p style={{width:'300px'}}>{"ChariTrek® exists to connect people through experiences. We use technology to provide a dynamic digital environment to share information, adventures and interests. We make it easy to create and enjoy personal journeys that connect humanity and enhance lives."}</p>

							<a onClick={e => setBg(2)}>2</a>
							<a onClick={e => setBg(3)}>3</a>
							<a onClick={e => setBg(4)}>4</a>
						</div>
	} */

	return (
		<div className='page'>
			<div className='left'>
				<TransitionGroup className='left-trans' style={{backgroundColor:'rgb(102,102,102)'}}>
					{/*
						This is no different than other usage of
						<CSSTransition>, just make sure to pass
						`location` to `Switch` so it can match
						the old location as it animates out.
					*/}
					<CSSTransition
						key={bg}
						classNames="fade"
						timeout={500}
					>
						<div className={`bg slide-${bg}`} style={{backgroundImage:`url('${process.env.PUBLIC_URL + `/images/bg/${bg}.jpeg`}')`, ...BG_STYLE_OVERRIDE?.[bg]}} />
					</CSSTransition>
				</TransitionGroup>
				<TransitionGroup className='left-content'>
					<CSSTransition
						key={bg}
						classNames="pa-fade"
						timeout={250}>
						{photoArea}
					</CSSTransition>
				</TransitionGroup>
			</div>
			<div className='right'>
				<div className='topo' style={{backgroundImage:`url('${process.env.PUBLIC_URL + `/images/topo.svg`}')`, ...COLOR_OVERRIDE?.[bg]}}/>
				<TransitionGroup className='right-trans'>
					<CSSTransition
						key={bg}
						classNames="fade"
						timeout={250}
					>
						{formArea}
					</CSSTransition>
				</TransitionGroup>
			</div>
		</div>
	)
})
