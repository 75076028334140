import React from 'react'
import FooterStyle from './Footer.scss'
import { Link } from 'react-router-dom'

export default function Footer() {
	const year = new Date().getFullYear()
	return (
		<div className='footer'>
			<ul>
				<li>© 2014-{year}. All Rights Reserved.</li>
				<li><Link to="/legal/terms">Terms &amp; Conditions</Link></li>
				<li><Link to="/legal/privacy">Privacy Policy</Link></li>
				<li><Link to="/legal/refund">Refund Policy</Link></li>
			</ul>
		</div>
	)
}
