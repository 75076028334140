import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useField, splitFormProps } from 'react-form'

const styles = {
	checkboxWrapper: {
		display: 'inline-flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-start',
		position: 'relative',
		width: '100%'
	},
	checkbox: {
		position: 'relative',
		display: 'flex',
		outline: 'none',
		flexDirection: 'row',
		alignItems: 'center',
	},
	icon: {
		fontSize: '2em',
		left:0,
		cursor:'pointer',
		outline: 'none',
		userSelect: 'none',
	},
	check: {
		position: 'absolute',
		left: 0,
	}
}
export default forwardRef((props, ref) => {
	const [enabled, setEnabled] = useState(props.enabled)

	const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

	const {
	  value = props.checked,
		setValue,
		meta: { isTouched, error }
	} = useField(field, fieldOptions);

	useEffect(() => {
		setValue(props.checked)
	}, [props.checked, setValue])

	useImperativeHandle(ref,
		() => ({
			setCheckbox(value) {
				setValue(value)
			}
		}),
		[setValue],
	)

	return (
		<div style={{...styles.checkboxWrapper, ...props.style}}
			onClick={() => {
				setValue(!value)
				if (props.onClick) props.onClick(!value)
			}}>
			<div style={styles.checkbox} >
				<i style={styles.icon} className='ct-r'>&#xf147;</i>
				{value && <i style={{...styles.icon, ...styles.check}} className='ct-r'>&#xf140;</i>}
				{props.children}
			</div>
			{/* {isTouched && error ? <em>{error}</em> : null} */}
		</div>
	)
})
