import React, { Fragment } from 'react'
import "./Info.scss"
import { useQuery } from "react-query"
import { axios } from 'utils/axios'

const Info = () => {

	const { isSuccess, status, data, error, isFetching } = useQuery(['company'], () => axios.get(process.env.REACT_APP_API_URL + `/api/v1/company`, { _auth: false })
	, {
		// enabled: false,
		// enabled: !tokenQuery.tokenExpired(tokens),
		// retry: false,
		cacheTime: 8 * 60 * 1000
		// retry: authRequired
		// refetch: false,
		// retry: false,
	})

	console.log("data", {
		isSuccess,
		status,
		data, error,
		isFetching
	})

	if (data?.length === 0) {
		return null
	}

	return (
		<div className='info'>
			This is Info home.
			{ data?.data?.map(({title, message, createdAt}, id) => (
				<Fragment key={`post-${id}`}>
					<div>{JSON.stringify(title)}</div>
					<div>{JSON.stringify(message)}</div>
					<div>{Date(createdAt).toLocaleString()}</div>
				</Fragment>
			))}
		</div>
	)
}

export default Info
