import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { Link, useLocation } from 'react-router-dom'
import { axios } from "utils/axios"
import "./Owned.scss"
import { useQuery, useInfiniteQuery } from "react-query"
import TrekCategory from './components/TrekCategory'

function Owned() {
	// const [data, setData] = useState({})
	// const tokens = tokenQuery.useToken()
	const location = useLocation()
	// console.log("location", location)
	// const { nearby, search, near, within } = values
	const urlVars = new URLSearchParams(location.search)
	urlVars.set('limit', 30)
	for (const param of urlVars) {
		console.log(param)
	}
	// console.log('Search tokens', tokens)
	// const tokens = useSelector(AS.tokens)

	const { status, data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery(['token', 'owned'], async ({pageParam = 0}) => {
		// console.log('tokens?.accessToken', tokens?.accessToken)
		urlVars.set('page', pageParam)
		console.log('pageParam', pageParam)
		return axios.get(process.env.REACT_APP_API_URL + `/t/owned`, {
			_auth: true,
			// headers: {
			// 	cookie:'',
			// },
			// ...tokens?.accessToken ? { headers: { Authorization: `Bearer ${tokens?.accessToken}` } } : {},
		  // withCredentials: true,
		  // validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
		});
	}, {
		// cacheTime: 5 * 60 * 1000,
		// initialData:() => window.__ct?.SERVER_DATA ?? undefined
		getNextPageParam: (lastPage, pages) => {
			console.log('pages', pages, lastPage.data)
			return lastPage.data.nextPage
		}
	})
	console.log('data', status, data?.pages, error, hasNextPage, isFetching)

	const [type, setType] = useState('list')
	// if (isFetching)
	// 	return null

	let treks = data?.pages.map(page => page.data)
	if (data?.pages) {
		treks = treks.flat().filter(v => v)
	}
	console.log('treks', treks)

	return (
		<div className='owned-page'>
		{(status === 'loading') ? (
				<h3 className='error'>Loading...</h3>
			) : status === 'error' ? (
				<h3 className='error'>There was an error searching: {data?.error}</h3>
			) : !treks.length ? (
				<h3 className='error'>There were no treks found.</h3>
			) : (
				<div>
					<TrekCategory full treks={treks} category={ treks?.length > 0 ? `Owned treks:` : `No owned treks.` } />
					<button style={{
						padding: 20,
						width: 200,
						border: 'none',
						margin: '0 auto',
					}}
						onClick={() => fetchNextPage()}
						disabled={!hasNextPage || isFetchingNextPage}
					>
						{isFetchingNextPage
							? 'Loading more...'
							: hasNextPage
							? 'Load More'
							: ''}
					</button>
					<div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
				</div>)
		}
	</div> )
}

export default Owned
