import React, { useState, useReducer } from 'react'

export const PurchaseContext = React.createContext({
  show: false,
  title: 'Purchase',
  content: undefined,
  setContent: () => {},
  onConfirm: () => {},
  onCancel: () => {},
  showPurchase: () => {},
  showInstructions: () => {},
  hide: () => {},
})

