import './styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import './fonts/fonts.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from "react-router-dom"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { store, persistor } from 'store'
// import { setQueryClient } from 'utils/token'
import { toast } from 'react-toastify'
import { default as AxiosProvider, setQueryClient as axiosQueryClient }  from 'utils/axios'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'


const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage})
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			queryFn: () => {},
			staleTime: 10 * 60 * 1000,
			cacheTime: 10 * 60 * 1000,
			retry: 1,
			// retry: false,
		}
	}
})
persistQueryClient({
	queryClient,
	persistor: localStoragePersistor,
})
// const history = useHistory()
const onLogout = () => {
	// queryClient.clear()
	toast.warn("You have been signed out.", {
		toastId: "signedOut",
		autoClose: 10000,
		pauseOnFocusLoss:true,
		onClose: () => {
			// window.location.href = '/login'
		}
	})
}
// console.log('queryClient', queryClient)
// setQueryClient(queryClient)
axiosQueryClient(queryClient)
ReactDOM.render(
	<AxiosProvider onLogout={onLogout}>
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} />
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<React.Fragment>
						<Router>
							<App />
						</Router>
					</React.Fragment>
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	</AxiosProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
