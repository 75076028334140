import React, { useState, useContext, useEffect } from 'react'
import { PurchaseContext } from 'contexts/purchaseContext'
import "./Purchase.scss"
import Preview from "./purchase/Preview"
import Signin from "./purchase/Signin"
import Payment from "./purchase/Payment"
import Instructions from "./purchase/Instructions"
import Play from "./play/Play"
import { useQuery } from 'react-query'
import { axios } from 'utils/axios'
import { useSelector } from 'react-redux'
import { selectors as AS } from 'reducers/app'

function Purchase() {
	const {show, hide, content:uuid, title, stateOverride, onConfirm, onCancel} = useContext(PurchaseContext)
	const [state, setState] = useState(stateOverride ?? 'preview')

	const { zip, ...user } = useSelector(AS?.user) ?? {}
	const taxParams = new URLSearchParams({
		zip
	})

	const { status, data:trekContent } = useQuery(`/t/id/${uuid}`, () => axios.get(process.env.REACT_APP_API_URL + `/t/id/${uuid}/detail`, { _auth:true, _json:true }), { enabled:show })
	const { data:taxData, ...taxQuery } = useQuery('tax_rate', () => axios.get(process.env.REACT_APP_API_URL + `/api/v1/taxrate/lookup?${taxParams.toString()}`, { _json:true }), { enabled:show })

	// console.log('taxData', taxData, user)

	const { total:taxRate } = taxData?.data ?? { total:"..." }
	// console.log('taxRate', taxRate)

	useEffect(() => {
		setState(stateOverride ?? 'preview')
		console.log('stateOverride', stateOverride)
	}, [stateOverride])

	if (!show) {
		if (state !== 'preview' && !stateOverride)
			setState('preview')
		return null
	}
	console.log('status', status, trekContent)

	// console.log('content', content)
	return (
		<div className='purchaseModal'>
			<div className='bg' onClick={hide} />
			<div className='modal'>
				<div className='top'>
					<button onClick={hide}><span>◀︎</span> Back</button>
				</div>
				{ state === 'preview' ? (
					<Preview trek={trekContent?.data?.[0] ?? trekContent?.[0]} onNext={() => {
						if (!user)
							setState('signin')
						else
							setState('payment')
					}} /> // use 'signin' when live.
				) : state === 'signin' ? (
					<Signin onNext={() => setState('preview')} />
				) : state === 'payment' ? (
					<Payment trek={trekContent?.data ?? trekContent} taxRate={taxRate} onNext={() => setState('instructions')} />
				) : state === 'instructions' ? (
					<Instructions trek={trekContent?.data ?? trekContent} />
				) : state === 'play' ? (
					<Play trek={trekContent?.data ?? trekContent} />
				) : null }
			</div>
		</div>
	)
}

export default Purchase
