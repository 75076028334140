import React, { useEffect, useState, memo} from 'react'
import { globalStyle as GS, globalFunc as GF, Colors } from '../global/styles'
import "./ReviewIcon.scss"

const ReviewIcon = memo((props) => {
	const [base, setBase] = useState()
	const value = parseInt(props.value, 10)
	// const value = parseInt(parseInt(Math.random()*3), 10)

	useEffect(() => {
		// console.log('props.type.substr(0,3)', props.type.substr(0,3))
		setBase(props.type.substr(0,3) === "dif" ? 0x0100 :
				props.type.substr(0,3) === "edu" ? 0x0200 :
				props.type.substr(0,3) === "fun" ? 0x0300 : 0)

	}, [setBase, props.type])

	// useEffect(() => {
	// 	console.log("base", base, props.value)
	// }, [base, props.value])
	return (
		<span className={"reviewIcon"}>
			<i className='icon ct-i main'>{String.fromCharCode(0xf000 + base + value)}</i>
			{value >= 0 && <i className='icon ct-i'>{String.fromCharCode(0xf000 + 0)}</i>}
			{value >= 1 && <i className='icon ct-i'>{String.fromCharCode(0xf000 + 1)}</i>}
			{value >= 2 && <i className='icon ct-i'>{String.fromCharCode(0xf000 + 2)}</i>}
		</span>
	)
})

export default ReviewIcon
