import React, { useEffect } from 'react'
import { Header, Footer } from "../layout"
import { useLocation } from "react-router-dom";

export default function Legal(props) {
	const { pathname } = useLocation();
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<Header loginAvailable={false}/>
				<div className="legal">
					{props.children}
				</div>
			<Footer/>
		</>
	)
}
