import React, { useEffect, useState } from 'react';
import './App.css';
import Meta from './pages/layout/Meta'
import Home from './pages/Home'
import Test from './pages/Test'
import Splash from './pages/Splash'
import NoMatch from './pages/NoMatch'
import Error from './pages/Error'
import TrekDetails from './pages/app/browse/TrekDetail'
import Browse from './pages/app/browse/Browse'
import AppIndex from './pages/app'
import { Refund, Terms, Privacy, Legal } from './pages/legal'
import AuthPage from './pages/auth'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { parseJSON } from './utils/json'

import {
  // BrowserRouter as Router,
  Switch,
	Route,
	Redirect,
	// Link,
	// useParams,
	// useRouteMatch,
	useLocation,
	useHistory,
} from "react-router-dom";
import axios from 'axios'
// import { useQuery, queryCache } from 'react-query'
import { useAuthentication } from './hooks/useAuthentication'
import { useSelector, useDispatch } from 'react-redux'
import { AA, AS } from 'reducers/app'

function App() {
	const [redirect, setRedirect] = useState()
	const [statusCode, setStatusCode] = useState(window.__ct.SERVER_STATUS)
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch()
	const tokens = useSelector(AS.tokens)

	// const user = queryCache.getQueryData('user') ?? localStorage.getItem('user') ?? window.__ct?.USER
	// const tokens = queryCache.getQueryData('tokens') ?? parseJSON(localStorage.getItem('tokens'))
	// console.log('token not expired yet:', tokens?.expires > Date.now())
	// const getReq = useAuthentication(process.env.REACT_APP_API_URL + '/me', tokens.accessToken)
	/* const { status, data, error, isFetching } = useQuery('user', async () => {
		try {
			console.log("Trying to update user.", location.pathname)
			let config = tokens
				? { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
				: {}
			const {data, status} = await axios.get(process.env.REACT_APP_API_URL + '/me', {
				...config,
				// withCredentials: true,
				validateStatus: function (status) { return status < 500; } // Resolve only if the status code is less than 500
			});
			console.log("res status", status)
			localStorage.setItem('user', JSON.stringify(data))
			if (status === 200) {
				console.log("res 200", data)
				return data
			} else if (status === 403) {
				if (data.location === '/user/verify') {
					dispatch(AA.setState({ setup:true, verified:false }))
				}


				console.log("location.pathname !== '/'", location.pathname !== '/')
				if (location.pathname !== '/' && location.pathname !== '/logout' && location.pathname !== '/auth/setup' && !/\/legal\//gim.test(location.pathname)) {
					console.log("res 403", data)
					toast(data?.error, { toastId:'app403error' })
					// setRedirect(data?.location)
					if (!!data?.location)
						history.push(data?.location)
					return data
				} else {
					return false
				}
			} else if (status === 401) {
				console.log("res 401", data)
				// setRedirect('/login')
			}
			return false
		} catch(e) {
			console.log('e', e)
			return false
		}
	}, {
		retry:0,
		staleTime: 30 * 60 * 1000, // 2 min
		initialData: tokens?.expires > Date.now()
			? parseJSON(localStorage.getItem('user')) ?? window.__ct?.USER
			: undefined
	}); */
	// console.log("user", user)
	// const {path, url} = useRouteMatch()
	// console.log('path', path)
	// console.log('url', url)
	// console.log('location', location)

	useEffect(() => {
		if (statusCode === 302) {
			const newLoc = window.__ct.SERVER_DATA
			const flash = window.__ct?.FLASH
			if (flash) {
				let config = { toastId:"redirectMsg" }
				if (!!flash?.permanent) {
					config.autoClose = false
				}
				if (flash?.success)
					toast(window.__ct?.FLASH?.success, config)
				else if (flash?.error)
					toast.error(window.__ct?.FLASH?.error, config)
			}
			history.replace(newLoc)
		}
	// eslint-disable-next-line
	}, [statusCode])

	if (statusCode === 403) {
		return (<NoMatch title="Forbidden" subTitle="This page cannot be viewed at this time." />)
	} else if (statusCode >= 500) {
		return (<Error />)
	}

  return (
    <div className="App">
			<Meta />
			<ToastContainer
				position="top-center"
				autoClose={5000}
				transition={Slide}
				hideProgressBar
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				limit={5}
				pauseOnHover
				/>
			<Switch>
				<Redirect exact from='/profile' to='/alpha/get-started'/>
				<Route exact path='/' component={Splash} />
				<Route exact path='/index2' component={Test} />
				<Route exact path='/login' component={AuthPage} />
				<Route exact path='/register' component={AuthPage} />
				<Route exact path='/alpha/thanks' component={AuthPage} />
				<Route exact path='/alpha/get-started' component={AuthPage} />
				<Route path='/auth/*' component={AuthPage} />
				<Route exact path='/user/verify' component={AuthPage} />
				{/* <Route exact path='/auth/setup' component={AuthPage} /> */}
				{/* <Route exact path='/auth/forgot' component={AuthPage} /> */}
				{/* <Route exact path='/auth/reset' component={AuthPage} /> */}
				<Route exact path='/test/error' component={Error} />
				<Route exact path='/browse' component={AppIndex} />
				<Route exact path='/browse/saved' component={AppIndex} />
				<Route exact path='/browse/owned' component={AppIndex} />
				<Route exact path='/search' component={AppIndex} />
				<Route exact path='/account' component={AppIndex} />
				<Route exact path='/info' component={AppIndex} />
				{/* <Route exact path='/account' component={AppIndex} /> */}
				<Route exact path='/t/id/:uuid' component={AppIndex} />
				<Route exact path='/t/id/:uuid/dl' component={AppIndex} />
				<Route exact path='/legal/refund'><Legal><Refund/></Legal></Route>
				<Route exact path='/legal/privacy'><Legal><Privacy/></Legal></Route>
				<Route exact path='/legal/terms'><Legal><Terms/></Legal></Route>
				<Route path="*">
					<AppIndex />
				</Route>
			</Switch>
    </div>
  );
}

export default App;
