import React from 'react'
import { Link } from 'react-router-dom'
import "./AppFooter.scss"

const AppFooter = () => {
	const year = (new Date()).getFullYear()
	return (
		<div className='app-footer'>
			<div className='container'>
				<div className="legal-text">
					<div className='legal-links'>
						<Link to="/legal/terms">Terms & Conditions</Link>
						<Link to="/legal/refund">Refund Policy</Link>
						<Link to="/legal/privacy">Privacy Policy</Link>
						<Link to="/legal/disclaimers">Disclaimers</Link>
					</div>
					<p className='copyright'>&copy; 2014-{year} ChariTrek Inc. All Rights Reserved.</p>
				</div>
				<div className='social-media'>
					<i className='icon ct-r'>&#xf111;</i>
					<i className='icon ct-r'>&#xf113;</i>
					<i className='icon ct-r'>&#xf136;</i>
					<i className='icon ct-r'>&#xf118;</i>
				</div>
			</div>
		</div>
	)
}

export default AppFooter
