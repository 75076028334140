import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'sagas'

const config = {
  key: 'charitrek_web',
  storage
}
const sagaMiddleware = createSagaMiddleware()
const reducer = persistCombineReducers(config, {
	...rootReducer,
})
export const store = createStore(reducer, undefined, applyMiddleware(sagaMiddleware))
export const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

const Store = { store, persistor }
export default Store
