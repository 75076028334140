import React, { memo, useState, useEffect, useRef, useMemo } from 'react'
import "./Search.scss"
import { useHistory, useLocation } from "react-router-dom"
import { useForm, useField } from 'react-form'
import { TweenMax } from 'gsap'
import Checkbox from 'pages/auth/components/Checkbox'

const Search = () => {
	// const [value, setValue] = useState('')
	const [icon, setIcon] = useState('search')
	const [locationDisabled, setLocationDisabled] = useState(false)
	const [advancedToggle, setAdvanced] = useState(false)
	// const updateInput = (event) => {
	// 	setValue(event.target.value)
	// }
	const checkbox = useRef()
	const advIcon = useRef()
	const advContainer = useRef()
	const history = useHistory()
	const location = useLocation()
	const search = useMemo(() => new URLSearchParams(location.search), [location.search])

	const clear = () => {
		setValue('')
	}

	const focusInput = () => {
		TweenMax.to(advIcon.current, 0.25, { autoAlpha:1 })
	}
	const defocus = () => { if(value.length === 0 && !advancedToggle) TweenMax.to(advIcon.current, 0.25, { autoAlpha:0 }) }
	const advanced = () => {
		const toggle = !advancedToggle
		TweenMax.to(advContainer.current, 0.25, { scaleY:toggle ? 1 : 0 })
		TweenMax.to(advIcon.current, 0.25, { rotation: toggle ? 180 : 0 })
		setAdvanced(toggle)
	}

	useEffect(() => {
		if (!!search.q)
			TweenMax.set(advIcon.current, { autoAlpha:0 })
	}, [search])

	const { Form, meta, reset, handleSubmit, formContext } = useForm({
		onSubmit: async (values, instance) => {
			const { nearby, search, near, within } = values
			const urlVars = new URLSearchParams()
			urlVars.set('q', search)
			if (nearby) urlVars.set('nearby', nearby)
			if (near) urlVars.set('near', near)
			if (within) urlVars.set('within', within)
			history.push(`/search?${urlVars.toString()}`)
			// console.log("value submit", values, instance)
		}
	})
	const { value, setValue, meta: { error, isTouched, isValidating }, getInputProps } = useField("search", { validate: (value, instance) => value === '', defaultValue:search.get('q') ?? '', formContext })
	const { setValue:setNearby, getInputProps:getNearInputProps } = useField("near", { validate: (value, instance) => value === '', defaultValue:search.get('near') ?? '', formContext})
	const { setValue:setWithin, getInputProps:getWithinInputProps } = useField("within", { validate: (value, instance) => value === '', defaultValue:search.get('maxDistance') ?? '', formContext})
	const handleEnterSubmit = (target) => target.charCode === 13 ? handleSubmit() : undefined
	return (
		<div className='search'>
			<i className='search-icon ct-r search'>&#xf143;</i>
			<Form>
				<input type='text' className='search-box' placeholder='' {...getInputProps()} onFocus={focusInput} onBlur={defocus} onKeyPress={handleEnterSubmit} />

				{ value.length > 0 && <i className='search-icon ct-r close' onClick={clear}>&#xf109;</i>}
				<i ref={advIcon} className='search-icon ct-r adv' onClick={advanced}>&#xf104;</i>
				<div ref={advContainer} className={`advanced-container`}>
					<div className='location-pref'>
						<input type='text' {...getNearInputProps()} disabled={locationDisabled} placeholder="City, State" onFocus={() => checkbox?.current?.setCheckbox(false) } onKeyPress={handleEnterSubmit} />
						<p>or</p>
						<div className='btn-near'>
							<Checkbox ref={checkbox} onClick={(checked) => {
								setNearby('')
								setLocationDisabled(checked)
							}} field='nearby'><p style={{textAlign:"left"}}>Nearby</p></Checkbox>
						</div>
					</div>
					<div className='within'>
						<p>within</p><input type='number' {...getWithinInputProps()} placeholder="200" onKeyPress={handleEnterSubmit} /><p>miles</p>
					</div>
				</div>
			</Form>
		</div>
	)
}

export default Search
