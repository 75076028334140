import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import axios from 'axios'
import Helmet from 'react-helmet'
// import { queryCache } from 'react-query'
import { parseJSON } from '../../utils/json'
import { store } from 'store'
import { AA } from 'reducers/app'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

let emailSent = false
const UserVerify = {
	auth: true,
	mount: (dispatch) => {
		console.log("On verify mount.")
	},
	onSubmit:async (values, instance, cancelToken, dispatch) => {
		// await new Promise(resolve => setTimeout(resolve, 10000));
		// const params = new URLSearchParams();
		// params.append('param1', 'value1');
		if (emailSent)
			return [{id:'errorEmailSent', message:"E-mail was already sent."}]
		const tokens = store.getState().app.tokens
		// const tokens = queryCache.getQueryData('tokens') ?? parseJSON(localStorage.getItem('tokens'))
		let config = tokens ? { headers: { Authorization: `Bearer ${tokens.accessToken}` } } : {}
		let {data, status} = await axios.post(process.env.REACT_APP_API_URL + `/user/resendVerification`, values, {
			...config,
			cancelToken,
			validateStatus: function (status) { return status < 500; /* Resolve only if the status code is less than 500 */ }
		})
		if (status === 200)
		{
			if (!!data.success)
			{
				emailSent = true
				return [null, "Another e-mail was sent"]
			} else if (!!data.error) {
				return [{id:'errorEmailResend', message:data.error}]
			}
			return [null, data]
		} else {
			if (status === 403) {
				dispatch(AA.setState({setup:false}))
			} else if (status === 401)
				return [null, null, '/login']
			if (data?.location)
				return [{id:'errorEmailResend', message:data.error}, null, data?.location]
			return [{id:"errorEmailResend", message:"There was an error trying to verify email."}]
		}
	},
	bg:BG.KAYAK,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>Almost there.</h1>
				</div>
				<p>Your e-mail address just needs to be verified before you can continue and use the app.</p>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Verify Email - ChariTrek</title>
			</Helmet>
			<h3>Haven't received it yet?<br/></h3>
			<Form>
				<button type="submit" disabled={isSubmitting || !canSubmit}>
					{isSubmitting ? "Resending..." : "Resend Email"}
				</button>
			</Form>
		</div>
	)
}
export default UserVerify
