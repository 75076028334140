import React from 'react'
import './Error.scss'
import Helmet from 'react-helmet'
// import Lottie from 'react-lottie'

export default function Error() {
	return (
		<div class="fivehundred" >
			{/* <div className="bg" style={{backgroundPosition:`center`, backgroundImage:`url(${process.env.PUBLIC_URL + '/images/bg/error.jpg'})`}} /> */}
			<Helmet>
				<script src="https://cdnjs.cloudflare.com/ajax/libs/lottie-player/1.5.7/lottie-player.min.js" />
			</Helmet>
			<div style={{width:'300px', height:'300px'}} dangerouslySetInnerHTML={{__html:`
				<lottie-player src="/animations/UploadingError.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop="" autoplay=""/>
			`}} />
			<div class="main">
				<h1>Internal Server Error</h1>
				<h2>Something isn't right here.</h2>
				<p class="error">
					A team of highly trained mechanics is working on this as we speak.<br/><br/>
					If the problem persists, please <a href="mailto://support@charitrek.com">contact the system administrator</a> and inform them of the time that the error occured, and anything you might have done that may have caused the error.</p>
			</div>
		</div>
	)
}
