import React from 'react'
import { Link } from 'react-router-dom'
import { default as Page, BG } from './Page'
import { useForm, useField, splitFormProps } from 'react-form'
import { isEmpty } from 'lodash';
import logo from "../../assets/CTLogo.svg"
import { globalStyle as GS, globalFunc as GF, Colors } from '../../global/styles'
import InputField from './components/InputField'
import Axios from 'axios'
import Helmet from 'react-helmet'

async function sendToFakeServer(values) {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return values;
}

const Signup = {
	auth: false,
	onSubmit:async (values, instance, cancelToken) => {
		// await new Promise(resolve => setTimeout(resolve, 10000));
		// const params = new URLSearchParams();
		// params.append('param1', 'value1');
		let {data, status} = await Axios.post(process.env.REACT_APP_API_URL + `/alpha/create`, values, { cancelToken })
		if (status === 200) {
			return [null, data, '/alpha/thanks']
		} else if (status === 422) {
			if (!!data.error) {
				return [{id:'errorReg', message:data.error}]
			}
			return [null, data]
		} else {
			return [{id:"errorReg", message:"There was an error registering."}]
		}
	},
	bg:BG.KAYAK,
	photoArea:() => (
		<div className='photo-area'>
			<div className='photo-area--wrapper'>
				<div className='title' style={{flexGrow:1, display:'flex', flexDirection:'column', justifyContent:"center", alignItems:'center'}}>
					<Link to="/"><img src={logo} alt='logo' style={{width:100}} /></Link>
					<h1 style={{marginTop:'40px', marginBottom:'20px'}}>LIVE. LEARN. GIVE.</h1>
				</div>
				<p>ChariTrek<sup>®</sup> exists to connect people through experiences. We use technology to provide a dynamic digital environment to share information, adventures and interests. We make it easy to create and enjoy personal journeys that connect humanity and enhance lives.</p>
			</div>
		</div>
	),
	formArea:({Form, meta:{ isSubmitting, canSubmit, isValid, isTouched }}) => (
		<div className='form-area'>
			<Helmet>
				<title>Register - ChariTrek</title>
			</Helmet>
			<div style={{flexGrow:1}}/>
			<h1>Register for the ALPHA today!</h1>
			<Form>
				<InputField field="email" placeholder="E-mail" type="email" required />
				<div>
					<button type="submit" disabled={isSubmitting || !canSubmit}>
						{isSubmitting ? "Registering..." : "Register"}
					</button>
				</div>
			</Form>
			<div style={{flexGrow:1}}/>
			<Link to="/login">
				<h4 style={{marginTop:'40px'}}>Already have a login?</h4>
			</Link>
		</div>
	)
}
export default Signup
