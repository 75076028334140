import Axios from "axios";
import React, { useContext, createContext, useMemo, useState, useEffect } from 'react'
// import LocalStorageService from "./services/storage/localstorageservice";
// import token from "./token";
import ObjectError from './ObjectError'
import { QueryObserver } from 'react-query'
import { isEqual } from 'lodash'
// import router from "./router/router";

// LocalstorageService
// const localStorageService = LocalStorageService.getService();
const queryKey = 'token'
let queryClient
export function setQueryClient (client) {
	queryClient = client
}
let attempts = 0
let toAttempt
export var axios
export const sendLogin = async function(values, params) {
	// console.log('values', values, params, arguments)
	/*
	meta = {
			deviceName: Constants.deviceName,
			platform: Constants.platform,
			version: Constants.expoVersion,
			deviceYearClass: Constants.deviceYearClass
		}
		*/
	return new Promise(async (resolve, reject) => {
		try {
			attempts++
			if (attempts < 6) {
				const vars = new URLSearchParams({
					next:false,
					id: 'web',
					meta:{
						deviceName: 'Web',
						platform: navigator.userAgent,
						version: 1,
						deviceYearClass: 'N/A'
					},
					...(values.remember) ? {permanent:true} : {}
				})
				// await new Promise(resolve => setTimeout(resolve, 100000));
				const { identifier, password } = values
				let res = await Axios.post(process.env.REACT_APP_API_URL + `/auth/local?${vars.toString()}`, { identifier, password }, {
					// cancelToken,
					validateStatus: function (status) { return status < 500; }, // Resolve only if the status code is less than 500 */
				})
				// console.log('res', res)
				if (res.status === 200)
				{
					const tokenData = {
						accessToken: res.data.tokens.accessToken,
						accessTokenExpiry: res.data.tokens.accessTokenExpiry,
						userId: res.data.userUuid,
						...(values.remember) ? {
							refreshToken: res.data.tokens.refreshToken,
							refreshTokenExpiry: res.data.tokens.refreshTokenExpiry
						} : {}
					}
					setTokenData(tokenData)
					resolve(tokenData)
					// dispatch(AA.setState({tokens:res.data?.tokens, user:res.data?.userUuid, loggedIn:true}))
					// dispatch({type:"SAGA_ME"})
					// return [null, res.data]
				} else if (res.status === 429) {
					reject(new ObjectError(undefined, "tooMany", "Too many attempts. Please try again in a minute."))
				} else {
					reject(new ObjectError(undefined, "invalidLogin", "Unable to login. \n\r\nPlease check your username and/or password" ))
				}
			} else {
				if (!toAttempt) {
					toAttempt = setTimeout(() => {
						attempts = 0
						toAttempt = undefined
					}, 30000)
				}
				reject(new ObjectError(undefined, "tooMany", "Too many attempts. Please try again in 30 seconds."))
			}
		} catch(e) {
			// console.log('e', e)
			if (e?.code)
				reject(e)
			reject(new ObjectError(undefined, "invalidLogin", "Unable to login. \n\r\nPlease check your username and/or password" ))
		}
	})
}

const sendRefresh = async function(token, save = false) {
	return new Promise(async (resolve, reject) => {
		// const tokens = yield call(api.post, app.url + '/auth/token', {refreshToken, user, identifier: Constants.installationId})
			// console.log('tokens', token)
		if (!token?.refreshToken)
			return reject('No refresh token.')
		let config = token?.accessToken ?
				{ headers: { Authorization: `Bearer ${token?.accessToken}` } } :
				{}
		let userId = token.userId
		let res = await Axios.post(process.env.REACT_APP_API_URL + `/auth/token`, {
			refreshToken: token.refreshToken,
			user: userId,
			identifier: 'web'
		}, {
			...config,
			// cancelToken,
			validateStatus: function (status) { return status < 500; }, // Resolve only if the status code is less than 500 */
		})
		// console.log('res', res)
		if (res.status === 200)
		{
			let t = {
				accessToken: res.data.accessToken,
				accessTokenExpiry: res.data.accessTokenExpiry,
				userId,
				refreshToken: res.data.refreshToken,
				refreshTokenExpiry: res.data.refreshTokenExpiry
			}
			if (save)
				setTokenData(t)
			resolve(t)
		} else {
			reject(new ObjectError({status: res.status}, "invalidRefresh", "Unable to refresh token."))
		}
	})
}

export const tokenExpired = (token) => {
	// console.log("check if token expired", token?.accessTokenExpiry === undefined || Date.now() > token.accessTokenExpiry, Date.now(), token?.accessTokenExpiry)
	return token?.accessTokenExpiry === undefined || Date.now() > token.accessTokenExpiry
}

export const refreshExpired = (token) => {
	// console.log("check if refresh is expired", token?.refreshTokenExpiry === undefined || Date.now() > token.refreshTokenExpiry)
	// return token?.refreshTokenExpiry !== undefined && Date.now() < token.refreshTokenExpiry
	return token?.refreshTokenExpiry === undefined || Date.now() > token.refreshTokenExpiry
	//
}
const parseKey = (queryKey) => {
	const item = localStorage.getItem(queryKey)
	if (item)
		return JSON.parse(item)
	return item
}
export const getTokenData = () => parseKey(queryKey) || queryClient.getQueryData(queryKey)
export const setTokenData = (tokenData) => {
	localStorage.setItem(queryKey, JSON.stringify(tokenData))
	queryClient.setQueryData(queryKey, tokenData)
}
export const checkAndRefreshToken = async () => {
	console.log("checking to refresh token")
	const token = getTokenData()
	if (tokenExpired(token))
		return await sendRefresh(token, true)
	else
		return token
}

export const authRequired = async (failureCount, error) => {
	console.log('failureCount', failureCount, error)
	if (failureCount < 1) {
		await checkAndRefreshToken().catch(e => {
			console.log('tokendetail error', e)
			logout()
		})
		return true
	} else
		return false
}

export const useToken = () => {
	const existingToken = queryClient.getQueryData(queryKey);
	const [token, setToken] = useState(existingToken);

	useEffect(() => {
		const observer = new QueryObserver(queryClient, { queryKey })
		const unsubscribe = observer.subscribe(async (newQueryCache) => {
			// console.log("updating token", tokenLockPromise)
			// await tokenLockPromise
			const newToken = queryClient.getQueryData(queryKey);
			// console.log('useEffect in useToken: newQueryCache', newQueryCache, newToken)
			if (!isEqual(token, newToken)) {
				setToken(newToken);
			}
		});

		return () => {
			unsubscribe();
		};
	});

	// console.log("useToken token", token)
	return token;
}
export const loggedIn = () => {
	const token = getTokenData()
	// console.log('check if logged in:', token?.userId)
	return ((token?.accessToken) ? token.userId : undefined)
}
export const logout = () => {
	console.log("Logging out")
	localStorage.removeItem(queryKey)
	queryClient.clear()
	// if (onLogout) onLogout()
}

let asyncBlockResolve = null // func for blocking async request
let asyncBlock = null // func for blocking async request
export const AxiosContext = createContext();
export default function AxiosProvider({ children, onLogout }) {
	axios = useMemo(() => {
		const axios = Axios.create({
			headers: {
				"Content-Type": "application/json"
			}
		})

		// Add a request interceptor
		axios.interceptors.request.use(async config => {
			let token
			let t
			if (config._auth) {
				t = await new Promise(async (res, rej) => {
					let d = Date.now()
					console.log("res check wait", d)
					let wait = await asyncBlock
					console.log("res check wait result", d)
					token = getTokenData()
					if (token) { // Token exists
						if (tokenExpired(token)) { // Token is expired
							asyncBlock = new Promise((g, x) => { asyncBlockResolve = g; }) // Setup a blocker for the remaining async requests.
							token = await sendRefresh(token, true).then(t => {
								asyncBlockResolve()
								res(t)
							}).catch(rej) // Unable to refresh token
						} // else token wasn't expired
						res(token)
					} else { // no token, but it is required.
						rej('No token, but required')
					}
				}).then(token => {
					if (token?.accessToken) { config.headers['Authorization'] = `Bearer ${token.accessToken}` }
					return token
				}).catch(err => {
					console.error('initial request error', JSON.stringify(err))
					if (err.data.status === 400 && err.code === 'invalidRefresh')
						logout()
					// if (onLogout) onLogout()
					// logout()
					return Promise.reject({ error:"Authorization required.", config, status:err.data.status, code:err.code })
				})
				//  else {
				// 	if (onLogout) onLogout()
				// 	logout()
				// 	return Promise.reject("Authorization required.")
				// }
			}

			// console.log("test3", JSON.parse(JSON.stringify(config)), JSON.stringify(t))
			if (config._json)
				config.headers['Content-Type'] = 'application/json';
			return config;
		},
			error => {
				Promise.reject(error)
			}
		);

		//Add a response interceptor
		axios.interceptors.response.use((response) => response, async function (error) {
			const originalRequest = error.config;
			console.log("error", JSON.stringify(error))
			console.log('originalRequest.url', originalRequest?.url)
			// TODO change this url to something else that would be accurate.
			// if (error?.status === 400 && originalRequest?.url === process.env.REACT_APP_API_URL + '/auth/token') {
			// 	if (onLogout) onLogout()
			// 	return Promise.reject(error);
			// }

			if (error?.status === 401 && !originalRequest._retry && originalRequest._auth) {
				originalRequest._retry = true;
				let d = Date.now()
				console.log("res check wait", d)
				let wait = await asyncBlock
				console.log("res check wait result", d)
				const token = getTokenData()
				return new Promise((res, rej) => {
					if (tokenExpired(token)) {
						asyncBlock = new Promise((g, x) => { asyncBlockResolve = g; }) // Setup a blocker for the remaining async requests.
						sendRefresh(token, true).then(t => {
							asyncBlockResolve()
							res(t)
						}).catch(rej)
					} else res(token)
				}).then(newToken => {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken.accessToken;
					return axios(originalRequest);
				}).catch(e => {
					console.log('error with 2nd request', e)
					if (onLogout) onLogout()
					logout()
					return Promise.reject(e)
				})
			}
			return Promise.reject(error);
		});
		return axios
	}, [onLogout])
	// }, [])

	return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return useContext(AxiosContext);
}
